import {EventBackend} from "./EventBackend";
import Event from "./Event";
import {Goal} from "./Goal";
import {BackendService2} from "../../../infra/BackendService2";
import {Action} from "./Action";


export class EventBackendService extends BackendService2 implements EventBackend {
    saveEvent(event: Event): Promise<string> {
        const path = "/api/v2/event/register";
        const url = this.getUrl(path);

        const payload: string = JSON.stringify(event);
        console.log(path);
        return EventBackendService.post(payload, url);
    }

    saveGoal(goal: Goal): Promise<string> {
        const path = "/api/v2/goal/register";
        const url = this.getUrl(path);

        const payload: string = JSON.stringify(goal);

        return EventBackendService.post(payload, url);
    }

    updateEvent(event: Event): Promise<string> {
        const path = "/api/v2/event/update";
        const url = this.getUrl(path);

        const payload: string = JSON.stringify(event);

        return EventBackendService.put(url, payload);
    }

    updateGoal(goal: Goal): Promise<string> {
        const path = "/api/v2/goal/update";
        const url = this.getUrl(path);

        const payload: string = JSON.stringify(goal);

        return EventBackendService.put(url, payload);
    }

    deleteEvent(id: string): Promise<void> {
        const path = "/api/v2/event/delete/" + id;
        const url = this.getUrl(path);

        return EventBackendService.delete(url);
    }

    deleteGoal(id: string): Promise<void> {
        const path = "/api/v2/goal/delete/" + id;
        const url = this.getUrl(path);

        return EventBackendService.delete(url);
    }

    getLatestEvents(actionName: string, actionVersion: number, limit: number): Promise<Event[]> {
        const path = "/api/v2/event/latest/" + actionName + "/" + actionVersion + "/" + limit;
        const url = this.getUrl(path);

        return EventBackendService.get(url) as Promise<Event[]>;
    }

    getEvent(id: string): Promise<Event> {
        const path = "/api/v2/event/" + id;
        const url = this.getUrl(path);

        return EventBackendService.get(url) as Promise<Event>;
    }

    getLatestGoals(actionName: string, actionVersion: number, limit: number): Promise<Goal[]> {
        const path = "/api/v2/goal/latest/" + actionName + "/" + actionVersion + "/" + limit;
        const url = this.getUrl(path);

        return EventBackendService.get(url) as Promise<Goal[]>;
    }

    getUrl(path: string): string {
        let host: string;
        if (process.env.REACT_APP_LOCALHOST === undefined) {
            host = '';
        } else {
            host = process.env.REACT_APP_LOCALHOST;
        }

        return host + path;
    }

    getPrefilledValues(action: Action): Promise<{ name: string; value: string | string[] }[]> {
        const path = `/api/v2/event/prefilled/${action.name}/${action.version}`
        const url = this.getUrl(path);

        return EventBackendService.get(url) as Promise<{ name: string; value: string | string[] }[]>;
    }
}
