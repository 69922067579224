import {ActionService} from "../../register/v2/ActionService";
import {StatisticsService} from "../../register/v2/StatsticsService";
import React from "react";
import {Action, Field} from "../../register/v2/Action";
import Graphs from "../../graph/Graphs";
import Selection from "./Selection";
import Filter from "./Filter";
import {addValueToGraphOptions, AgeFilterSelection, GraphOptions} from "./GraphOptions";
import {STATISTICS} from "../../../infra/Constants";
import FormHeader from "../../register/v2/FormHeader";
import TranslationService from "../../../infra/TranslationService";
import {DEFAULT_MOVING_AVERAGE, GRAPH_TYPE_DISTRIBUTION} from "../Constants";
import Value from "../../register/v2/Value";
import {User} from "../../model/User";
import {Organisation} from "../../model/Organisation";
import ExportEventsButton from "../../splashpage/ExportEventsButton";
import {hasRight} from "../../../utils/HasRight";
import RegisterResultFailureMessage from "../../register/v2/RegisterResult";


interface props {
    action: Action,
    actionService: ActionService,
    statisticsService: StatisticsService,
    user: User,
    currentOrganisation?: Organisation,
}

interface state {
    graphOptions: GraphOptions,
    showGoals: boolean,
    updateGraphs: boolean,
    showFailureMessage: boolean,
}

class Statistics extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.onGroupByChange = this.onGroupByChange.bind(this);
        this.onGraphTypeChange = this.onGraphTypeChange.bind(this);
        this.onMovingAverageChange = this.onMovingAverageChange.bind(this);
        Statistics.onAgeChange = Statistics.onAgeChange.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.onAgeSelectionChange = this.onAgeSelectionChange.bind(this);
        this.onPeriodChange = this.onPeriodChange.bind(this);
        this.onFirstEventsChange = this.onFirstEventsChange.bind(this);
        this.onLastEventsChange = this.onLastEventsChange.bind(this);
        this.onAllEventsChange = this.onAllEventsChange.bind(this);

        let groupBy: string = '';
        if (this.props.action.groupBy !== undefined) {
            groupBy = this.props.action.groupBy;
        }
        const currentOrganisation: Organisation | undefined = this.props.currentOrganisation;

        const graphOptions: GraphOptions = {
            groupBy: groupBy,
            currentOrganisation: currentOrganisation,
            graphType: GRAPH_TYPE_DISTRIBUTION,
            movingAverage: DEFAULT_MOVING_AVERAGE
        };

        this.state = {
            graphOptions: graphOptions,
            showGoals: false,
            updateGraphs: true,
            showFailureMessage: false
        }
    }

    render(): React.ReactNode {
        const actionName: string = this.props.action.name;
        const actionVersion: number = this.props.action.version;
        const graphOptions: GraphOptions = this.state.graphOptions;
        const statisticsService: StatisticsService = this.props.statisticsService;
        const action: Action = this.props.action;
        const user: User = this.props.user;

        const formHeader: React.JSX.Element = Statistics.getFormHeader(actionName);
        const graphs: React.ReactFragment = this.getGraphs(graphOptions, actionName, actionVersion, statisticsService);
        const searchButton: React.ReactFragment = this.getSearchButton();
        const selection: React.JSX.Element = this.getSelection(action);
        const filter: React.ReactFragment = this.getFilter(action, graphOptions, user);
        let failureModal: React.JSX.Element = <div/>;
        const failureMessage = ["Något gick tokigt! Vi jobbar på att lösa det."];

        if (this.state.showFailureMessage) {
            failureModal =
                <RegisterResultFailureMessage message={failureMessage}
                                              hideFailureMessage={this.hideFailureMessage}/>;
        }
        return <div>
            {formHeader}
            <div className={"container"}>
                {graphs}
                {searchButton}
                {failureModal}
                {selection}
                {filter}
            </div>
        </div>;
    }

    private static getFormHeader(actionName: string) {
        return <div>
            <FormHeader actionName={actionName} actionType={STATISTICS}/>
        </div>
    }

    private getGraphs(graphOptions: GraphOptions, actionName: string, actionVersion: number, statisticsService: StatisticsService): React.ReactFragment {
        if (graphOptions.groupBy === '') {
            return <div/>;
        }

        if (this.state.updateGraphs) {
            return <Graphs key={'1'}
                           actionName={actionName}
                           actionVersion={actionVersion}
                           showDescription={true}
                           graphOptions={graphOptions}
                           statisticsService={statisticsService}
                           graphPlaceholder={undefined}
            />;
        } else {
            const graphPlaceholder: any = {
                "data": {
                    "columns": [],
                    "type": "pie"
                }
            };

            return <Graphs key={'2'}
                           actionName={actionName}
                           actionVersion={actionVersion}
                           showDescription={true}
                           graphOptions={graphOptions}
                           statisticsService={statisticsService}
                           graphPlaceholder={graphPlaceholder}
            />;
        }
    }

    private getSearchButton(): React.ReactFragment {
        const searchTranslation: string = TranslationService.translation("show graphs");

        const searchButton: React.JSX.Element = <div className={"d-flex justify-content-end pt-3 m-0"}>
            <button aria-label={"search button"}
                    name={'search'}
                    className={"btn btn-submitNewReg"}
                    onClick={() => this.search()}>
                {searchTranslation}
            </button>
        </div>;

        let actionName = this.props.action.name;
        let version = this.props.action.version;
        let user = this.props.user;

        const hasExportRight = hasRight(user, actionName, "export-events");

        const exportButton: React.JSX.Element = <div className={"btn-save d-flex justify-content-end pt-3 m-0"}>
            <ExportEventsButton hasExportRight={hasExportRight}
                                buttonText={"Ladda ner din data"}
                                showFailureMessage={this.showFailureMessage}
                                actionName={actionName}
                                version={version}
                                scope={'user'}

            />
        </div>

        let organisation: Organisation | undefined = this.props.currentOrganisation;
        let exportOrganisationButton: React.JSX.Element = <div/>;
        if (organisation) {
            let name = organisation.organisationName;
            let rightName = actionName + "-" + name;
            let rightType = "export-organisation-events";
            let hasOrganisationExportRight = hasRight(user, rightName, rightType);
            let alias: string = this.getOrganisationAlias(name);
            let buttonText = "Exportera " + alias + "s registreringar";

            let organisationId = organisation.organisationId;
            exportOrganisationButton = <div className={"btn-save d-flex justify-content-end pt-3 m-0"}>
                <ExportEventsButton hasExportRight={hasOrganisationExportRight}
                                    buttonText={buttonText}
                                    showFailureMessage={this.showFailureMessage}
                                    actionName={actionName}
                                    version={version}
                                    organisationId={organisationId}
                                    scope={'organisation'}
                />
            </div>
        }

        const hasWorldExportRight: boolean = hasRight(user, actionName, "export-world-events");
        let worldButtonText = "Ladda ner all data";
        const exportWorldButton: React.JSX.Element = <div className={"btn-save d-flex justify-content-end m-0"}>
            <ExportEventsButton hasExportRight={hasWorldExportRight}
                                buttonText={worldButtonText}
                                showFailureMessage={this.showFailureMessage}
                                actionName={actionName}
                                version={version}
                                scope={'world'}
            />
        </div>

        // const searchCriteria: string = 'Include the search criteria';
        const searchCriteria = this.getSearchCriteria();

        return <div>
            <hr/>
            <div className={"row"}>
                <div className={"col-10 filtering-values"}>
                    {searchCriteria}
                </div>
                <div className={"col-2"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            {searchButton}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col"}>
                            {exportButton}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col"}>
                            {exportOrganisationButton}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col"}>
                            {exportWorldButton}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    hideFailureMessage = () => {
        this.setState({showFailureMessage: false})
    }

    showFailureMessage = (showFailureMessage: boolean) => {
        this.setState({showFailureMessage: showFailureMessage})
    }

    private getOrganisationAlias(organisation: string): string {
        let alias = organisation.replace(" - Ambulanshelikopter", "");
        alias = alias.replace("sregionen", "");
        return alias.replace("Region ", "");
    }

    private getSelection(action: Action): React.JSX.Element {
        const graphOptions: GraphOptions = this.state.graphOptions;
        const user: User = this.props.user;

        return <Selection action={action}
                          graphOptions={graphOptions}
                          user={user}
                          selectedGroupBy={graphOptions.groupBy}
                          onGroupByChange={this.onGroupByChange}
                          onGraphTypeChange={this.onGraphTypeChange}
                          onMovingAverageChange={this.onMovingAverageChange}
                          onPeriodChange={this.onPeriodChange}
                          onFirstEventsChange={this.onFirstEventsChange}
                          onLastEventsChange={this.onLastEventsChange}
                          onAllEventsChange={this.onAllEventsChange}
        />;
    }

    private getFilter(action: Action, graphOptions: GraphOptions, user: any): React.JSX.Element {
        const onAgeChange = Statistics.onAgeChange;
        const onValueChange = this.onValueChange;
        const onAgeSelectionChange = this.onAgeSelectionChange;

        return <Filter action={action}
                       user={user}
                       graphOptions={graphOptions}
                       onAgeChange={onAgeChange}
                       onValueChange={onValueChange}
                       onAgeSelectionChange={onAgeSelectionChange}
        />;
    }

    private search() {
        this.setState({
            updateGraphs: true
        });
    }

    private onGroupByChange(groupBy: string): void {
        const graphOptions: GraphOptions = this.state.graphOptions;
        graphOptions.groupBy = groupBy;

        this.setState({
            graphOptions: graphOptions,
            updateGraphs: false
        });
    };

    private onGraphTypeChange(graphType: string): void {
        const graphOptions: GraphOptions = this.state.graphOptions;
        graphOptions.graphType = graphType;

        this.setState({
            graphOptions: graphOptions,
            updateGraphs: false
        });
    };

    private onMovingAverageChange(movingAverage: number | undefined): void {
        const graphOptions: GraphOptions = this.state.graphOptions;
        graphOptions.movingAverage = movingAverage;

        this.setState({
            graphOptions: graphOptions,
            updateGraphs: false
        });
    };

    private onPeriodChange(from: string, to: string): void {
        const graphOptions: GraphOptions = this.state.graphOptions;

        graphOptions.periodSelection = {
            periodFrom: from,
            periodTo: to
        };

        graphOptions.lastSelection = undefined;
        graphOptions.firstSelection = undefined;

        this.setState({
            graphOptions: graphOptions,
            updateGraphs: false
        });
    }

    private onFirstEventsChange(firstEvents: number | undefined): void {
        const graphOptions: GraphOptions = this.state.graphOptions;
        graphOptions.periodSelection = undefined;
        graphOptions.lastSelection = undefined;

        if (firstEvents !== undefined) {
            graphOptions.firstSelection = {amount: firstEvents};
        } else {
            graphOptions.firstSelection = undefined;
        }

        this.setState({
            graphOptions: graphOptions,
            updateGraphs: false
        });
    }

    private onLastEventsChange(lastEvents: number | undefined): void {
        const graphOptions: GraphOptions = this.state.graphOptions;
        graphOptions.periodSelection = undefined;
        graphOptions.firstSelection = undefined;

        if (lastEvents !== undefined) {
            graphOptions.lastSelection = {amount: lastEvents};
        } else {
            graphOptions.lastSelection = undefined;
        }

        this.setState({
            graphOptions: graphOptions,
            updateGraphs: false
        });
    }

    private onAllEventsChange(): void {
        const graphOptions: GraphOptions = this.state.graphOptions;
        graphOptions.periodSelection = undefined;
        graphOptions.firstSelection = undefined;
        graphOptions.lastSelection = undefined;

        this.setState({
            graphOptions: graphOptions,
            updateGraphs: false
        });
    };

    private static onAgeChange(): void {
        // todo implement me
        throw Error('Not yet implemented');
    };

    private onValueChange(name: string, value: (string | string[]), duplicationIndex: string, valid: boolean, field: Field): void {
        if (name === duplicationIndex === valid) {
            // Trick Idea to think these arguments are used
        }
        if (field.type === undefined) {
            // Trick Idea to think these arguments are used
        }

        let currentGraphOptions = this.state.graphOptions;
        let graphOptions = addValueToGraphOptions(value, name, currentGraphOptions);

        this.setState({
            graphOptions: graphOptions,
            updateGraphs: false
        });
    };

    private onAgeSelectionChange(ageSelection: AgeFilterSelection): void {
        const graphOptions: GraphOptions = this.state.graphOptions;

        if (graphOptions.ageSelection === undefined) {
            graphOptions.ageSelection = [];
            graphOptions.ageSelection.push(ageSelection);
        } else {
            let ageFilterSelections: AgeFilterSelection [] = graphOptions.ageSelection.filter((ags: AgeFilterSelection) => ags.name !== ageSelection.name);
            ageFilterSelections.push(ageSelection);
            graphOptions.ageSelection = ageFilterSelections;
        }

        this.setState({graphOptions: graphOptions});
    }

    private getSearchCriteria() {
        const filters: any[] = []
        const groupings: any[] = [];


        const deconstructedGroupings = Statistics.groupingDeconstructor(this.state.graphOptions.groupBy);
        deconstructedGroupings.reverse();

        deconstructedGroupings.forEach((grouping: string, index: number) => {
            groupings.push(<div key={grouping} style={{paddingLeft: (index * 24) + "px"}}
                                className={"row m-0"}>&#8226; {TranslationService.translation(grouping)}</div>)
        })

        this.state.graphOptions.values?.forEach((field: Value, index: number) => {
            filters.push(<div key={field.fieldName + field.duplicationIndex + index}
                              className={"row m-0 p-0"}>{TranslationService.translation(field.fieldName)}:
                <div className={"container m-0 p-0"}>
                    {
                        field.values.map((val) => <div key={val}
                                                       className={"row m-0 pl-4"}>&#8226; {TranslationService.translation(val)}</div>)
                    }
                </div>
            </div>)
        })

        return <div className={"container m-0 p-0"}>
            <div className={"row m-0 pb-1"}>
                <div className={"col-6"}>
                    <h5>Gruppera enligt:</h5>
                    {groupings}
                </div>
                <div className={"col-6"}>
                    <h5>Nuvarande filter:</h5>
                    {filters}
                </div>
            </div>
        </div>
    }

    static groupingDeconstructor(input: string): string[] {
        if (input) {
            const ret: string[] = [];
            const separated = input.split('.');

            for (let i = 0; i < separated.length; i++) {
                if (separated[i]) {
                    let value = "";
                    if (i > 0) {
                        value = ret[i - 1] + "." + separated[i]
                    } else {
                        value = separated[i];
                    }
                    ret.push(value)
                }
            }

            ret.reverse();
            ret.pop();
            return ret;
        }
        return [];
    }
}

export default Statistics;
