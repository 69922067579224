import React, {ChangeEvent, ReactElement} from 'react';
import {FieldComponent, FieldError, FieldProps} from "./FieldComponent";
import TranslationService from "../../../infra/TranslationService";
import translationService from "../../../infra/TranslationService";
import Value from "../../register/v2/Value";
import {validateDate} from "../v1/validators/DateValidator";
import {createSingleValue, getValid, invalidDate, missingMandatoryField} from "./FieldUtil";
import {V3} from "../../../infra/Constants";
import {Field} from "../../register/v2/Action";

interface props extends FieldProps {
    dateFormat: string,
    frontendVersion?: string,
    value?: string,
    duplicationIndex?: string,
    onChange?: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void
}

interface state {
    value: string,
    errorMessage: string
}

class DateField extends React.Component<props, state> implements FieldComponent {
    constructor(props: Readonly<props>) {
        super(props);

        this.state = {
            value: '',
            errorMessage: ''
        }
    }

    componentDidMount() {
        this.setDefaultValue();
    }

    render(): ReactElement {
        const name = this.props.field.name;
        const labelText: string = TranslationService.translation(name);
        let showLabel: boolean = true;
        if (this.props.field.showLabel !== undefined) {
            showLabel = this.props.field.showLabel;
        }

        let fieldSize = 12;
        if (this.props.field.fieldSize !== undefined) {
            fieldSize = this.props.field.fieldSize;
        }

        const placeholder = TranslationService.translation(this.props.dateFormat);

        const onChange = (e: ChangeEvent<HTMLInputElement>) => this.onChange(e);

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            let value: string = '';
            if (this.props.value !== undefined) {
                value = this.props.value;
            }

            const field = DateField.getField(labelText, showLabel, name, value, fieldSize, placeholder, onChange);

            return <div>
                {field}
            </div>
        } else {
            const value = this.state.value;
            const field = DateField.getField(labelText, showLabel, name, value, fieldSize, placeholder, onChange);

            return <div>
                {field}
            </div>;
        }
    }

    private setDefaultValue() {
        let defaultValue: string | undefined = this.props.field.defaultValue;
        if (defaultValue !== undefined) {
            let value: string = getDefaultDateValue(defaultValue);

            this.setState({value: value});
        } else {
            this.setState({value: ""});
        }
    }

    private static getField(labelText: string,
                            showLabel: boolean,
                            name: string,
                            value: string,
                            fieldSize: number,
                            placeholder: string,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => void): React.ReactFragment {

        const label = <h5>
            <div className={"row"}>
                <div className={"col"}>
                    <label htmlFor={name}
                           data-testid={name + '.label'}>
                        {labelText}
                    </label>
                </div>
            </div>
        </h5>;

        const field = <div className={"row"}>
            <div className={"col"}>
                <input type={"text"}
                       size={fieldSize}
                       id={name}
                       placeholder={placeholder}
                       data-testid={name}
                       aria-label={name}
                       name={name}
                       value={value}
                       onChange={onChange}
                />
            </div>
        </div>;

        if (!showLabel) {
            return <>
                {field}
            </>;
        }

        return <>
            {label}
            {field}
        </>;
    }

    private onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            if (this.props.onChange) {
                let field = this.props.field;
                const name: string = field.name;
                let duplicationIndex: string = '0';
                if (this.props.duplicationIndex !== undefined) {
                    duplicationIndex = this.props.duplicationIndex;
                }
                const value: string = e.currentTarget.value;
                const format: string = this.props.dateFormat;

                let mandatory: boolean = false;
                if (field.mandatory !== undefined) {
                    mandatory = field.mandatory;
                }

                let valid: boolean;
                if (mandatory) {
                    const mandatoryEvaluation = DateField.isMandatory(value, format, name);
                    valid = mandatoryEvaluation.valid;
                    this.setState({errorMessage: mandatoryEvaluation.errorMessage});
                } else {
                    valid = validateDate(value, format);

                    if (valid) {
                        this.setState({errorMessage: ""});
                    } else {
                        const errorMessage: string = translationService.translation(name) + ' ' + translationService.translation('invalid date');
                        this.setState({errorMessage: errorMessage});
                    }
                }

                this.props.onChange(name, value, duplicationIndex, valid, field);
            }
        } else {
            this.setState({value: e.currentTarget.value});
        }
    }

    static isMandatory(value: string, format: string, name: string): { valid: boolean, errorMessage: string } {
        const valid: boolean = validateDate(value, format);
        let errorMessage = '';

        const present: boolean = value !== '';
        if (valid && present) {
            return {valid: true, errorMessage: errorMessage};
        } else {
            if (!present) {
                errorMessage = translationService.translation(name) + ' ' + translationService.translation('mandatory field');
            }

            if (!valid) {
                errorMessage = translationService.translation(name) + ' ' + translationService.translation('invalid date');
            }

            return {valid: false, errorMessage: errorMessage};
        }
    }

    values(): Value[] {
        const name = this.props.field.name;
        const value = this.state.value;
        if (this.isValid().valid) {
            if (value !== "") {
                return createSingleValue(name, value);
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    isValid(): FieldError {
        const name = this.props.field.name;
        const format = this.props.dateFormat;
        const value = this.state.value;
        let mandatory: boolean = false;

        if (this.props.field.mandatory !== undefined) {
            mandatory = this.props.field.mandatory;
        }

        if (mandatory) {
            const valid = value !== "";
            if (valid) {
                const validDate = validateDate(value, format);
                if (validDate) {
                    return getValid(name);
                } else {
                    return getValid(name, invalidDate);
                }
            } else {
                return getValid(name, missingMandatoryField);
            }
        }

        const validDate = validateDate(value, format);
        if (validDate) {
            return getValid(name);
        } else {
            return getValid(name, invalidDate);
        }
    }

    clear(): void {
        this.setDefaultValue();
    }

    set(values: Value[]): void {
        const name = this.props.field.name;
        values.forEach((value: Value) => {
            if (value.fieldName === name) {
                const newValue: string = value.values[0];
                this.setState({value: newValue});
            }
        });
    }
}

export default DateField;

export function getDefaultDateValue(defaultValue: string) {
    if (defaultValue === 'today') {
        const isoDate = usersCurrentDate();

        return isoDate.substr(0, 10);
    }

    if (defaultValue === 'firstDayOfMonth') {
        const isoDate = usersCurrentDate();
        const today: string = isoDate.substr(0, 8);

        return today + "01";
    }

    return '';
}

function usersCurrentDate(): string {
    const now: Date = new Date();
    const timezoneOffset: number = now.getTimezoneOffset();
    let offset = timezoneOffset * 60 * 1000;
    let currentTime = now.getTime();
    const adjusted = new Date(currentTime - offset);

    return adjusted.toISOString();
}
