import React from 'react';
import {Binder, BinderId, Book, BookId, Drug, DrugId} from "../models/Models";
import {EventBackendService} from "../../register/v2/EventBackendService";
import {KnarkbokService} from "../Knarkbokservice";
import "./TempKBControlPanel.css"
import KBControlPanelEditPopup, {KBControlPanelMode} from "./KBControlPanelEditPopup";
import MinusLogo from "../../../images/minus-512.png"
import Logo from "../../../images/Logga_swedish.png"
import PlusLogo from "../../../images/plus-512.png"
import {BackendContext, BackendInterface} from "../../../infra/BackendContext";
import {generateUUID} from "../../../utils/TsCrypto";
import QRCode from "react-qr-code";
import {jsPDF} from "jspdf";
import * as htmlToImage from 'html-to-image';
import {Organisation} from "../../model/Organisation";

interface props {
    KBService: KnarkbokService,
    organisation: Organisation
}

interface state {
    drugName: string,
    location: string
    binders: Binder[],
    drugs: Drug[],
    books: Book[],
    bookToDelete: string | undefined,
    chosenBinder: string | undefined,
    binderToDelete: string | undefined,
    chosenDrug: string | undefined;
    drugToEdit: string | undefined,
    drugToEdit2: string | undefined,
    showEditDrug: boolean;
    binderToEdit: string | undefined;
    binderToEdit2: string | undefined;
    showEditBinder: boolean;
    qr: string | undefined;
    binderToQr: string | undefined,
    accessOnlyQr: boolean,
    acceptDelivery: boolean,
}

class TempKBControlPanel extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: any) {
        super(props);
        this.state = {
            drugName: "",
            location: "",
            binders: [],
            drugs: [],
            books: [],
            bookToDelete: undefined,
            drugToEdit: undefined,
            drugToEdit2: undefined,
            chosenBinder: undefined,
            binderToDelete: undefined,
            chosenDrug: undefined,
            showEditDrug: false,
            binderToEdit: undefined,
            binderToEdit2: undefined,
            showEditBinder: false,
            qr: undefined,
            binderToQr: undefined,
            accessOnlyQr: true,
            acceptDelivery: false
        }
    }

    async componentDidMount() {
        await this.getDrugsAndLocations()
    }

    render() {
        let binders: Binder[] = this.state.binders;

        //Updated Edit drug
        let editDrugPopup: React.JSX.Element = <div></div>

        if (this.state.drugToEdit2 && this.state.drugToEdit2 !== "default_empty") {
            const castDrugToEdit: number = +this.state.drugToEdit2;
            const drug: Drug | undefined = this.state.drugs.find(drug => drug.id?.id === castDrugToEdit);
            let drugName: string | undefined = undefined;
            let canEdit: boolean = true;
            if (drug !== undefined) {
                drugName = drug.name;
                canEdit = drug.canEdit;
            }

            editDrugPopup = <KBControlPanelEditPopup closeFocus={this.clearEditDrugPopup2}
                                                     displayString={drugName}
                                                     canEdit={canEdit}
                                                     updateValue={this.handleUpdatedDrugValue}
                                                     mode={KBControlPanelMode.drug}
                                                     updateWhenDone={() => this.getDrugsAndLocations()}
                                                     id={{id: castDrugToEdit}}/>;
        }

        //Edit location and location popup part
        let editLocationPopup: React.JSX.Element = <div></div>;
        if (this.state.binderToEdit2 && this.state.binderToEdit2 !== "default_empty") {
            const castBinderToEdit = +this.state.binderToEdit2;
            const binder: Binder | undefined = binders.find(binder =>
                binder.id?.id === castBinderToEdit
            );

            let binderName: string | undefined = undefined;
            let canEdit: boolean = true;
            let canBeArchived: boolean | undefined = undefined;

            if (binder !== undefined) {
                binderName = binder.locationName;
                canBeArchived = binder.canBeArchived;
                canEdit = binder.canBeEdited;
            }

            editLocationPopup = <KBControlPanelEditPopup closeFocus={this.clearEditBinderPopup2}
                                                         displayString={binderName}
                                                         canEdit={canEdit}
                                                         updateValue={this.handleUpdatedBinderValue}
                                                         acceptDelivery={binder?.acceptDelivery}
                                                         mode={KBControlPanelMode.binder}
                                                         updateWhenDone={() => this.getDrugsAndLocations()}
                                                         canBeArchived={canBeArchived}
                                                         id={{id: castBinderToEdit}}/>;
        }

        const {drugs, books, chosenBinder} = this.state;
        let sortedDrugs = drugs.sort();
        let sortedBooks = books.sort();

        let outsideCount = 0;
        let notIncludedDrugs = sortedDrugs.map((drug: Drug) => {
            let isHidden = false;
            const existsInCurrentBinder = sortedBooks.find((book: Book) => {
                let isCurrentBinder = book.binderId.id === (chosenBinder ? +chosenBinder : 0);
                isHidden = !!book.hidden;
                return book.drugId?.id === drug.id?.id && isCurrentBinder;
            })

            if ((!existsInCurrentBinder && drug.id) || (existsInCurrentBinder && isHidden)) {
                return <div
                    className={(outsideCount++ % 2 === 0) ? "grayed row journal-pad" : "row journal-pad"}
                    key={drug.id?.id}>
                    <div
                        className={"col-8 ml-0 pl-2"}>{drug.name}</div>
                    <div className={"col-4"}>
                        <button
                            title={"Lägg till läkemedel"}
                            className={"btn btn-invis-bg p-0 b-0 ml-1 btn-invis-add"}
                            onClick={() => this.addDrugToBook(drug.id || {id: -1})}>
                            <img alt={"add drug to journal"} height={18} src={PlusLogo}/>
                        </button>
                    </div>
                </div>
            }

            return <div/>
        });


        let orderDrugArray: { drugName: string, bookId: BookId }[] = [];
        sortedBooks.filter((b: Book) => b.binderId.id === (chosenBinder ? +chosenBinder : -1) && !(b.hidden))
            .forEach((book: Book) => {
                const found = drugs.find((drug: Drug) => book.drugId?.id === drug.id?.id);
                const drugName = found?.name || "";
                orderDrugArray.push({drugName: drugName, bookId: book.id})
            });

        orderDrugArray.sort(function (a, b) {
            if (a.drugName < b.drugName) {
                return -1;
            }
            if (a.drugName > b.drugName) {
                return 1;
            }
            return 0;
        });

        let includedDrugs = orderDrugArray.map((element: { drugName: string, bookId: BookId }, index: number) => {
            return <div
                className={(index % 2 === 0) ? "grayed row m-0 p-0" : "row m-0 p-0"}
                key={element.bookId.id}>
                <div
                    className={"col-8 ml-0 pl-2"}>
                    {element.drugName}
                </div>
                <div className={"col-4"}>
                    <button
                        title={"Göm läkemedel"}
                        className={"btn btn-invis-bg pl-1 pr-0 pb-0 pt-0 b-0"}
                        onClick={() => this.deleteBookValue(element.bookId)}>
                        <img alt={"remove drug from journal"} height={18} src={MinusLogo}/>
                    </button>
                </div>
            </div>

        })

        const location: string = this.state.location;
        const canCreateJournal: boolean = location === '';

        return <div aria-label={'drug-administrator'}
                    className={"container pt-4"}>
            <div className={"row m-0 p-0"}>
                <div className={"col admin-col mr-4 mb-4"}>
                    <div className={"row m-0 p-0"}>
                        <h5>Nytt läkemedel</h5>
                    </div>
                    <div className={"row m-0 pt-2"}>
                        <label>
                            Namn:&nbsp;
                            <input type={"text"} value={this.state.drugName}
                                   onChange={(e) => this.setDrugName(e)}/>
                        </label>
                    </div>
                    <div className={"row m-0 pt-5 justify-content-end"}>
                        <button className={"btn btn-tage-default mr-2"} onClick={(e) => this.postDrug(e)}>OK</button>
                    </div>
                    <hr/>
                    <div>
                        <div>
                            <div className={"row m-0 pt-2"}>
                                <label>
                                    <h6>
                                        Redigera läkemedel:
                                    </h6>
                                    <select value={this.state.drugToEdit2}
                                            id={"drugToEditSelect"}
                                            defaultValue={"default_empty"}
                                            onChange={(e) => this.handleDrugToEdit2(e)}>
                                        <option disabled value={"default_empty"}>-- Välj läkemedel --</option>
                                        {
                                            this.state.drugs.map((drug) => {
                                                return <option key={drug.id?.id}
                                                               value={drug.id?.id}>{drug.name}</option>
                                            })
                                        }
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    {editDrugPopup}
                </div>

                <div className={"col admin-col mr-4 mb-4"}>
                    <div className={"row m-0 p-0"}>
                        <h5>Ny Förbrukningsjournal</h5>
                    </div>
                    <div className={"row m-0 pt-2"}>
                        <label>
                            Namn:&nbsp;
                            <input type={"text"} value={location}
                                   onChange={(e) => this.setLocation(e)}/>
                        </label>
                    </div>
                    <div className={"row m-0 pt-2"}>
                        <div><input type={"checkbox"}
                                    onClick={() => this.flipAcceptDelivery()}
                                    checked={this.state.acceptDelivery}/>
                            Kan ta emot apoteksleverans
                        </div>
                    </div>
                    <div className={"row m-0 pt-5 justify-content-end"}>
                        <button className={"btn btn-tage-default mr-2"}
                                disabled={canCreateJournal}
                                onClick={(e) => this.postLocation(e)}>
                            OK
                        </button>
                    </div>
                    <hr/>
                    <div className={"row m-0 pt-2"}>
                        <label>
                            <h6>
                                Redigera journal:
                            </h6>
                            <select value={this.state.binderToEdit2}
                                    id={"editBinderSelect"}
                                    defaultValue={"default_empty"}
                                    onChange={(e) => this.handleBinderToEdit2(e)}>
                                <option disabled value={"default_empty"}>-- Välj journal --</option>
                                {
                                    binders.map((binder) => {
                                        return <option key={binder.id.id}
                                                       value={binder.id.id}>{binder.locationName}</option>
                                    })
                                }
                            </select>
                        </label>
                    </div>
                    {editLocationPopup}
                </div>

                <div className={"col admin-col mb-4"}>
                    <div className={"row m-0 p-0"}>
                        <h5>Hantera läkemedel i journal</h5>
                    </div>
                    <div className={"row m-0 pt-2"}>
                        <label>
                            Journal:
                            <select value={this.state.chosenBinder}
                                    id={"binderSelect"}
                                    defaultValue={"default_empty"}
                                    onChange={(e) => this.handleBinderSelect(e)}>
                                <option disabled value={"default_empty"}>-- Välj journal --</option>
                                {
                                    binders.map((binder) => {
                                        return <option key={binder.id.id}
                                                       value={binder.id.id}>{binder.locationName}</option>
                                    })
                                }
                            </select>
                        </label>
                    </div>

                    {
                        this.state.chosenBinder ? <div className={"row m-0 pt-2"}>
                            <div className={"col m-0 p-0 outline-journal"}>
                                <div className={"inside-journal-outline"}>
                                    <h6 className={"pt-2 pl-2"}>Läkemedel i journal:</h6>
                                    {includedDrugs}
                                    <hr/>
                                    <h6 className={"pl-2"}>Ej i journal:</h6>
                                    {notIncludedDrugs}
                                </div>
                            </div>
                        </div> : <div/>
                    }
                </div>

                <div className={"col-12 admin-col"}>
                    <div className={"row m-0 p-0"}>
                        <h5>Qr kod hantering</h5>
                    </div>
                    <div className={"row m-0 pt-2"}>
                        <div className={"col"}>
                            <label>
                                Journal:
                                <select value={this.state.binderToQr} id={"binderSelectQR"}
                                        defaultValue={"default_empty"}
                                        onChange={(e) => this.handleBinderToQr(e)}>
                                    <option disabled value={"default_empty"}>-- Välj journal --</option>
                                    {
                                        binders.map((binder) => {
                                            return <option key={binder.id.id}
                                                           value={binder.id.id}>{binder.locationName}</option>
                                        })
                                    }
                                </select>
                            </label>
                        </div>
                        <div className={"col text-right"}>
                            {
                                this.state.qr ?
                                    <button
                                        onClick={() => this.downloadPdf()}
                                        className={"btn btn-tage-default mr-2"}>Ladda som pdf
                                    </button> :
                                    this.state.binderToQr ?
                                        <button className={"btn btn-tage-default mr-2"}
                                                onClick={(e) => this.postQrLink(e)}>Skapa</button> :
                                        <button className={"btn btn-tage-default-cancel mr-2"}>Välj journal</button>


                            }
                        </div>
                    </div>
                    <div className={"row m-0 pt-2"}>
                        {
                            this.state.qr ? <div className={"container m-0 p-0"}>
                                <div className={"row m-0 p-0"}>QR sträng:&nbsp; {this.state.qr}
                                </div>
                                <div className={"row m-0 p-0"}>
                                    Länk till &nbsp;
                                    <a href={EventBackendService.getUrl2('/#/fjn/') + this.state.qr}>knarkbok</a>
                                    &nbsp;
                                </div>
                                <div className={"row m-0 pt-3"} id={"canvasTest"}>
                                    <QRCode
                                        size={256}
                                        style={{height: "auto", maxWidth: "100%", width: "100%"}}
                                        value={this.getQrUrl()}
                                        level={"H"}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                            </div> : ""
                        }
                    </div>

                </div>

            </div>
        </div>
    }

    async postDrug(e: any) {
        e.preventDefault();

        const drugName: string = this.state.drugName;
        const organisationId: string = this.props.organisation.organisationId;
        const payload = {
            name: drugName,
            organisationId: organisationId
        }

        const path = `/api/v1/knarkbok/drugs/`
        const url = EventBackendService.getUrl2(path);

        await this.context.post(url, JSON.stringify(payload), {
            success: "Skapade nytt läkemedel",
            failure: "Fel uppstod"
        })
        //const postResponse = await EventBackendService.post(JSON.stringify(payload), url);

        this.setState({drugName: ""});
        await this.getDrugsAndLocations();
    }

    private setDrugName(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({drugName: e.currentTarget.value});
    }

    private setLocation(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({location: e.currentTarget.value});
    }


    private async postLocation(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        const location: string = this.state.location;
        const accessOnlyQr: boolean = this.state.accessOnlyQr;
        const acceptDelivery: boolean = this.state.acceptDelivery;
        const organisationId: string = this.props.organisation.organisationId;
        const randomString = generateUUID();

        const payload = {
            location: location,
            accessOnlyQr: accessOnlyQr,
            organisationId: organisationId,
            acceptDelivery: acceptDelivery,
        }

        const path = `/api/v1/knarkbok/binders/`

        const url = EventBackendService.getUrl2(path);
        //const postResponse = await EventBackendService.post(JSON.stringify(payload), url);

        let createResponse = await this.context.post(url, JSON.stringify(payload), {
            success: "Skapade ny Journal",
            failure: "En journal med samma namn finns redan"
        })

        //the author thinks this is a silly way to solve this, he would just rather supply it in the original post. But since java isn't big on optional values this is the least amount of hassle
        if (createResponse.success) {
            const binder = createResponse.data.id
            const payloadQr = {
                qrPass: randomString,
                binderId: binder
            }

            const path = `/api/v1/knarkbok/qr`
            const url = EventBackendService.getUrl2(path);

            await this.context.post(url, JSON.stringify(payloadQr), {
                success: "",
                failure: ""
            })
        }

        this.setState({location: "", accessOnlyQr: true, acceptDelivery: false});
        await this.getDrugsAndLocations();
    }

    private async handleBinderToQr(e: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({binderToQr: e.currentTarget.value})
        const fetchedQrCode = await this.fetchQrCode(e);

        this.setState({qr: fetchedQrCode})
    }

    private async fetchQrCode(e: React.ChangeEvent<HTMLSelectElement>): Promise<string | undefined> {
        const path = `/api/v1/knarkbok/qr/${e.currentTarget.value}`
        const url = EventBackendService.getUrl2(path);
        const fetchedQrCode = await this.context.get(url, {
            failure: "Fel uppstod"
        })

        if (fetchedQrCode.data) {
            return fetchedQrCode.data.qr;
        }

        return undefined;
    }

    private async postQrLink(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        const randomString = generateUUID();

        const payload = {
            qrPass: randomString,
            binderId: {id: this.state.binderToQr}
        }

        const path = `/api/v1/knarkbok/qr`
        const url = EventBackendService.getUrl2(path);

        await this.context.post(url, JSON.stringify(payload), {
            success: `Länkade QR kod med Journal`,
            failure: "Fel uppstod"
        })

        this.setState({qr: undefined, binderToQr: "default_empty"});
        await this.getDrugsAndLocations();
    }

    private async getDrugsAndLocations() {
        let organisation: string = this.props.organisation.organisationId;

        const drugsPath: string = `/api/v1/knarkbok/drugs/${organisation}`
        const drugUrl: string = EventBackendService.getUrl2(drugsPath);
        let drugsResponse: Drug[] = await EventBackendService.get(drugUrl) as Drug[];
        if (!Array.isArray(drugsResponse)) {
            drugsResponse = [];
        }

        const bindersPath: string = `/api/v1/knarkbok/binders/${organisation}`
        const bindersUrl: string = EventBackendService.getUrl2(bindersPath);
        let bindersResponse: Binder[] = await EventBackendService.get(bindersUrl) as Binder[];
        if (!Array.isArray(bindersResponse)) {
            bindersResponse = [];
        }

        const booksPath: string = `/api/v1/knarkbok/books/all/${organisation}`
        const booksUrl: string = EventBackendService.getUrl2(booksPath);
        let booksResponse: Book[] = await EventBackendService.get(booksUrl) as Book[];

        if (!Array.isArray(booksResponse)) {
            booksResponse = [];
        }

        this.setState({
            drugs: drugsResponse,
            binders: bindersResponse,
            books: booksResponse,
            drugToEdit2: "default_empty",
            binderToEdit2: "default_empty"
        })
    }

    private handleBinderSelect(e: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({chosenBinder: e.currentTarget.value})

    }

    private async handleDrugSelectValue(drugId: string) {
        this.setState({chosenDrug: drugId})
    }


    private handleDrugToEdit2(e: React.ChangeEvent<HTMLSelectElement>) {
        this.state.drugs.find(drug => drug.id?.id === +e.currentTarget.value)
        this.setState({drugToEdit2: e.currentTarget.value})
    }

    private handleBinderToEdit2(e: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({binderToEdit2: e.currentTarget.value})
    }

    private async postDrugToBook() {
        const drugId: string | undefined = this.state.chosenDrug;
        const organisationId: string = this.props.organisation.organisationId;
        const binderId: string | undefined = this.state.chosenBinder;
        const payload = {
            drugId: {id: drugId},
            binderId: {id: binderId},
            organisationId: organisationId
        }

        const path = `/api/v1/knarkbok/books/`

        const url = EventBackendService.getUrl2(path);
        await this.context.post(url, JSON.stringify(payload), {
            success: "Nytt läkemedel i knarkbok",
            failure: "Fel uppstod"
        })

        this.setState({chosenDrug: "default_empty"});
        await this.getDrugsAndLocations();
    }

    private async deleteBookValue(bookId: BookId) {
        const path = `/api/v1/knarkbok/books/${bookId.id}`

        const url = EventBackendService.getUrl2(path);

        let deleteMessage = await this.context.delete(url, {success: "Gömde läkemedel", failure: "Fel uppstod"});
        if (!deleteMessage.success) {
            alert("Måste ha tomt saldo för att gömma läkemedel") //todo something more snazzy than an alert
        } else {
            this.setState({bookToDelete: "default_empty"});
        }
        await this.getDrugsAndLocations();
    }

    clearEditDrugPopup2 = () => {
        this.setState({drugToEdit2: "default_empty"})
    }

    clearEditBinderPopup2 = () => {
        this.setState({binderToEdit2: "default_empty"})
    }

    handleUpdatedDrugValue = async (value: string, id: DrugId | BinderId | undefined) => {
        const path = `/api/v1/knarkbok/drugs/`

        const url = EventBackendService.getUrl2(path);
        //const putResponse = await EventBackendService.put(url, JSON.stringify({id: id, name: value}));
        await this.context.put(url, JSON.stringify({id: id, name: value}), {
            success: "Uppdaterade läkemedel",
            failure: "Fel uppstod"
        })

        this.setState({drugToEdit2: "default_empty", showEditDrug: false});
        await this.getDrugsAndLocations();
    }

    handleUpdatedBinderValue = async (value: string, id: DrugId | BinderId | undefined, acceptDelivery?: boolean) => {
        const path = `/api/v1/knarkbok/binders/`

        const url = EventBackendService.getUrl2(path);
        //const putResponse = await EventBackendService.put(url, JSON.stringify({id: id, locationName: value}));
        let payload = JSON.stringify({
            id: id,
            locationName: value,
            acceptDelivery: !!acceptDelivery
        });

        await this.context.put(url, payload, {
            success: "Uppdaterade knarkbok",
            failure: "Fel uppstod"
        })

        this.setState({binderToEdit: "default_empty", showEditBinder: false});
        await this.getDrugsAndLocations();
    }


    private getQrUrl(): string {
        //TODO this needs to come from env and url you are using.
        return "https://www.logeze.se/#/fjn/" + this.state.qr
    }


    private downloadPdf() {
        const doc = new jsPDF('p', 'pt', 'a4');

        doc.setFillColor(0, 0, 0);
        const borderBoxWidth = 155;
        const borderBoxHeight = 300;
        const border = 2;
        const borderStartX = 220;
        const borderStartY = 20;
        const airGap = 10;
        doc.rect(borderStartX, borderStartY, borderBoxWidth, borderBoxHeight, 'F')

        doc.setFillColor(255, 255, 255);
        doc.rect(borderStartX + border, borderStartY + border, borderBoxWidth - 2 * border, borderBoxHeight - 2 * border, 'F')

        const binderToQr = this.state.binderToQr ? +this.state.binderToQr : 0;
        const foundBinder = this.state.binders.find((binder) => binder.id.id === binderToQr)
        const binderName = foundBinder?.locationName ? foundBinder.locationName : " "
        const startValueX = 230;
        doc.text(binderName, startValueX, 80);
        doc.text("Läkemedelsjournal", startValueX, 50);

        const canvasDiv = document.getElementById("canvasTest");

        const quarterSize = 135;
        const qrY = 95;
        if (canvasDiv) {
            htmlToImage.toPng(canvasDiv)
                .then(function (dataUrl) {
                    const date = new Date().toISOString();
                    doc.addImage(dataUrl, 'PNG', startValueX, qrY, quarterSize, quarterSize);
                    doc.save(`${binderName}-${date}.pdf`);
                });
        }

        let logo = new Image()
        logo.src = Logo;
        doc.addImage(logo, 'png', startValueX, qrY + quarterSize + airGap, quarterSize, 60)

        //x:135
        doc.setFontSize(5);
        doc.text("Kontaka vår support på support@logeze.com om du har problem", 225, 330);
        doc.text("med journalen eller allmänna frågor angående LogEze.", 225, 336);
    }

    private async addDrugToBook(id: DrugId) {
        await this.handleDrugSelectValue(id.id + '');
        this.postDrugToBook();
    }

    private flipAcceptDelivery() {
        this.setState({acceptDelivery: !this.state.acceptDelivery})
    }
}

export default TempKBControlPanel;
