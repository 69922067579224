import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import {UserOldRoles} from "../../model/UserOldRoles";
import {fetchArrayOf, fetchObjectOf} from "../../../infra/BackendService";
import EventDetailModal from "../../splashpage/EventDetailModal";
import TranslationService from "../../../infra/TranslationService";
import {Goal} from "../../register/v2/Goal";
import EditLogo from "../../../images/edit-225-225.png";
import {COPY_ALT_TEXT, DELETE_ALT_TEXT, EDIT_ALT_TEXT, VIEW_ALT_TEXT} from "../../../infra/Constants";
import Event from "../../register/v2/Event";
import CopyLogo from "../../../images/copy-225-225.png";
import ViewLogo from "../../../images/view-512.png";
import DeleteLogo from "../../../images/delete-512.png";
import {UpdateAble} from "../../register/v2/UpdateAble";
import {EventBackend} from "../../register/v2/EventBackend";

interface state {
    displayModal: boolean,
    modalData: EventField[] | null
    owner?: UserOldRoles,
    hasMore: boolean,
    totalDisplayed: number,
    header: EventHeaderInfo | undefined,
    eventRows: EventRow[],
    currentIndex: number,
    eventCount: number
}

interface props {
    backend: EventBackend,
    actionName: string,
    version: number,
    editEvent: (event: Event) => void,
    editGoal: (goal: Goal) => void,
    copyEvent: (event: Event) => void,
    copyGoal: (goal: Goal) => void,
    deleteEvent: (id: string) => void,
    deleteGoal: (id: string) => void,
    viewEvent: (event: Event) => void
}

export interface EventHeaderInfo {
    header: string[],
    hasCountersignInfo: boolean
}

interface EventRecords {
    [key: string]: any;
}

export interface EventRow {
    records: EventRecords
    eventId: string,
    user: UserOldRoles,
    mailOnDemandSent?: boolean,
    hasBeenCountersigned?: boolean,
    countersignInfo?: string
}

export interface EventDetail {
    missionFields: EventField[],
    shouldSee: boolean
}

export interface EventField {
    value: string,
    duplicationIndex: string
    level: number
}

class AllEventsTable extends React.Component<props, state> implements UpdateAble {
    constructor(props: any) {
        super(props);

        this.state = {
            displayModal: false,
            modalData: null,
            header: undefined,
            eventRows: [],
            hasMore: true,
            totalDisplayed: 0,
            currentIndex: 0,
            eventCount: 0,
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidMount() {
        let actionName = this.props.actionName;
        let version = this.props.version;
        let headerUrl: string = "/api/v2/events/header/" + actionName + "/" + version;
        fetchObjectOf<EventHeaderInfo>(headerUrl).then((header) => {
            this.setState({header: header})
        });
        this.update()
    }

    fetchData = () => {
        let actionName = this.props.actionName;
        let version = this.props.version;
        let index = this.state.currentIndex + 20;
        if (index >= this.state.eventCount) {
            this.setState({hasMore: false})
        } else {
            let eventsUrl: string = "/api/v2/events/" + actionName + "/" + version + "/" + index + "/20";
            fetchArrayOf<EventRow>(eventsUrl).then((eventRows) => {
                if (eventRows.length === 0) {
                    this.setState({hasMore: false})
                } else {
                    this.setState({
                        eventRows: [...this.state.eventRows, ...eventRows],
                        currentIndex: index
                    })
                }
            });
        }
    }

    render() {
        let modal = <EventDetailModal data={this.state.modalData}
                                      flipVisibility={this.flipVisibility} actionName={this.props.actionName}/>
        const tableStyle = {
            border: "none",
            boxShadow: "none"
        };
        let header = this.state.header;
        if (header === undefined) {
            return <div data-testid={"no-header-spinner"} className={"spinner"}/>;
        }
        let cols = header.header;
        let hasCountersignInfo = header.hasCountersignInfo;
        let headerColumns = cols.map((col: string) => {
            return <th key={col} className={"col-nowrap"} scope="col" aria-label={"table-header-cols"}
                       style={tableStyle}>{this.getTranslation(col)}</th>
        });
        let body = this.getBody(tableStyle, cols, hasCountersignInfo, header);
        let countersignHeaderCol = hasCountersignInfo ?
            <th className={"col-nowrap"} scope="col" style={tableStyle}>{this.getTranslation("countersign")}</th>
            : null;

        let table = <div className={"scroll-sideways"} aria-label={"all-events-table"}>
            <table className="table" style={tableStyle}>
                <thead>
                <tr style={tableStyle} className={"m-0 p-0"} aria-label={"events-header"}>
                    <th className={"col-nowrap"} scope="col" style={tableStyle}></th>
                    {headerColumns}
                    {countersignHeaderCol}
                </tr>
                </thead>
                <tbody>
                {body}
                </tbody>
            </table>
            {
                this.state.displayModal ? modal : null
            }
        </div>
        let loadMoreMissions = TranslationService.translation("Scrolla ner för mer");
        let noMoreMissions = TranslationService.translation("Inget mera registrerat");
        return <div>
            <InfiniteScroll
                dataLength={this.state.eventRows.length}
                next={this.fetchData}
                hasMore={this.state.hasMore}
                loader={<p style={{textAlign: 'center'}}>
                    <b>{loadMoreMissions}</b>
                </p>}
                endMessage={
                    <p style={{textAlign: 'center'}}>
                        <b>{noMoreMissions}</b>
                    </p>
                }
            >
                {table}
            </InfiniteScroll>
            <hr/>
        </div>
    }


    private getBody(tableStyle: {
        border: string;
        boxShadow: string
    }, cols: string[], hasCountersignInfo: boolean, header: EventHeaderInfo | undefined): React.JSX.Element[] {
        if (this.state.eventRows === undefined) {
            return [<tr>
                <td>You did nothing wrong, but something broke. Please contact support@logeze.com to help us solve the
                    problem.
                </td>
            </tr>]
        }
        return this.state.eventRows.map((row: EventRow, i: number) => {
            let buttons;
            let eventId = row.eventId;
            if (row.mailOnDemandSent) {
                buttons = this.viewEventButton(eventId)

            } else {
                if (row.hasBeenCountersigned) {
                    buttons = <div>
                        {this.copyEventButton(eventId)}
                    </div>;
                } else {
                    buttons = <div>
                        {this.deleteButton(eventId)}
                        {this.editEventButton(eventId)}
                        {this.copyEventButton(eventId)}
                    </div>;
                }
            }
            return (
                <tr key={eventId + i} className={"pt-1 pl-0 pr-0" + (i % 2 === 1 ? " grayed" : "")}
                    aria-label={"table-row"}
                    style={tableStyle}>
                    <td style={tableStyle}
                        className={"col-nowrap d-flex justify-content-end p-0 m-0"}>{buttons}</td>
                    {cols.map((header: string, j: number) => {
                        const recordKey: string | undefined =
                            Object.keys(row.records).find(key => key.toLowerCase() === header.toLowerCase());
                        const value = recordKey === undefined ? "" : row.records[recordKey];
                        return <td onClick={() => this.flipVisibility(eventId, row.user)}
                                   key={i + eventId + header + j}
                                   className={"col-nowrap"} aria-label={"table-column"}
                                   style={tableStyle}>{this.getTranslation(value)}</td>
                    })}
                    {hasCountersignInfo ?
                        <td onClick={() => this.flipVisibility(eventId, row.user)}
                            key={i + eventId + header}
                            className={"col-nowrap"} aria-label={"table-column"}
                            style={tableStyle}>{row.countersignInfo ? this.getTranslation(row.countersignInfo) : ''}</td> : null}

                </tr>);
        });
    }

    private getTranslation(key: string) {
        const historyKey = key + ".history";
        if (TranslationService.hasTranslation(historyKey)) {
            return TranslationService.translation(historyKey);
        } else {
            return TranslationService.translation(key);
        }
    }

    flipVisibility = (id?: string, user?: UserOldRoles) => {
        let actionName = this.props.actionName;
        let version = this.props.version;
        if (id) {
            let missionDetailUrl: string = "/api/v2/events/detail/" + id + "/" + actionName + "/" + version;
            fetchObjectOf<EventDetail>(missionDetailUrl).then((eventDetail) => {
                if (eventDetail !== undefined && eventDetail.shouldSee) {
                    this.setState({
                        modalData: eventDetail.missionFields,
                        displayModal: !this.state.displayModal
                    })
                }
            });
        } else {
            this.setState({displayModal: !this.state.displayModal});
        }
        if (user) {
            this.setState({owner: user})
        }

    }

    private editEventButton(eventId: string): React.ReactFragment {
        const buttonId: string = 'edit-button-' + eventId;
        const ariaLabel = buttonId;
        const onClick = () => this.edit(eventId);
        return AllEventsTable.getButton(ariaLabel, onClick, EditLogo, EDIT_ALT_TEXT, buttonId);
    }

    private copyEventButton(eventId: string): React.ReactFragment {
        const buttonId: string = 'copy-button-' + eventId;
        const ariaLabel = buttonId;
        const onClick = () => this.copy(eventId);
        return AllEventsTable.getButton(ariaLabel, onClick, CopyLogo, COPY_ALT_TEXT, buttonId);
    }

    private viewEventButton(eventId: string): React.ReactFragment {
        const buttonId: string = 'view-button-' + eventId;
        const ariaLabel = buttonId;
        const onClick = () => this.view(eventId);
        return AllEventsTable.getButton(ariaLabel, onClick, ViewLogo, VIEW_ALT_TEXT, buttonId);
    }

    private deleteButton(id: string | undefined): React.ReactFragment {
        if (id !== undefined) {
            const buttonId: string = 'delete-button-' + id;
            const ariaLabel = buttonId;
            const onClick = () => this.delete(id);
            return AllEventsTable.getButton(ariaLabel, onClick, DeleteLogo, DELETE_ALT_TEXT, buttonId);
        } else {
            return <div/>;
        }
    }

    private static getButton(ariaLabel: string, onClick: () => void, image: string, altText: string, buttonId?: string): React.ReactFragment {
        if (buttonId !== undefined) {
            return <button id={buttonId}
                           aria-label={ariaLabel}
                           className={"btn btn-invis-bg pl-1 b-0"}
                           onClick={onClick}>
                <img
                    height={18}
                    src={image}
                    alt={altText}/>
            </button>

        }

        return <button aria-label={ariaLabel}
                       className={"btn btn-invis-bg pl-1 b-0"}
                       onClick={onClick}>
            <img
                height={18}
                src={image}
                alt={altText}/>
        </button>

    }

    private edit(id: string) {
        this.props.backend.getEvent(id).then((event) =>
            this.props.editEvent(event)
        );

    }

    private copy(id: string) {
        this.props.backend.getEvent(id).then((event) =>
            this.props.copyEvent(event)
        );
    }

    private view(id: string) {
        this.props.backend.getEvent(id).then((event) =>
            this.props.viewEvent(event)
        );
    }

    private delete(id: string) {
        const deleteConfirmation: string = TranslationService.translation("deleteConfirmation");
        if (window.confirm(deleteConfirmation)) {
            this.props.deleteEvent(id);
        }

    }

    update(): void {
        let actionName = this.props.actionName;
        let version = this.props.version;
        let missionCountUrl: string = "/api/v2/events/count/" + actionName + "/" + version;
        fetchObjectOf<number>(missionCountUrl).then((count) => {
            if (count !== undefined) {
                this.setState({eventCount: count})
            }
        });
        let eventsUrl: string = "/api/v2/events/" + actionName + "/" + version + "/0/20"
        fetchArrayOf<EventRow>(eventsUrl).then((eventRows) => {
            this.setState({eventRows: eventRows})
        });
    }

}

export default AllEventsTable;
