import React from "react";
import {FieldProps} from "./FieldComponent";
import {fetchArrayOf} from "../../../infra/BackendService";
import TranslationService from "../../../infra/TranslationService";
import {Field} from "../../register/v2/Action";
import "./Select.css";
import Select from "react-select";
import {OptionType} from "../../model/SelectOption";


interface props extends FieldProps {
    optionsSource?: string
    frontendVersion?: string,
    value?: string[],
    duplicationIndex?: string,
    actionName?: string,
    version?: number,
    dependsOnFieldValue?: string | undefined,
    onChange?: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void
}

interface state {
    options: OptionType[],
    isMulti: boolean,
}


class PrehospitalFiltersDynamicSelect extends React.Component<props, state> {

    constructor(props: Readonly<props>) {
        super(props);

        this.onChangeSelect = this.onChangeSelect.bind(this);

        this.state = {
            options: [],
            isMulti: this.props.field.multiple ? this.props.field.multiple : false,
        }
    }

    componentDidMount() {
        this.retrieveOptions();
    }

    private retrieveOptions() {
        let isMulti = this.props.field.multiple ? this.props.field.multiple : false;
        let url: string = '/api/v2/reports/prehospital/filters';

        fetchArrayOf<string>(url).then((items: string[] | undefined) => {
            if (items !== undefined) {
                let options: OptionType[] = [];
                items.forEach((item: string) => {
                    let option = {
                        value: item,
                        label: TranslationService.translation(item)
                    }

                    options.push(option);
                })
                this.setState({options: options, isMulti: isMulti});
            }
        });
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        let showLabel: boolean = true;
        if (this.props.field.showLabel !== undefined) {
            showLabel = this.props.field.showLabel;
        }
        const name = this.props.field.name;
        const options = this.state.options;
        const onChange = this.onChangeSelect;
        const currentValue = options.length > 0 || this.props.value !== undefined ? this.getCurrentValue() : null;

        const isMulti = this.state.isMulti;
        return PrehospitalFiltersDynamicSelect.getField(currentValue, name, showLabel, options, onChange, isMulti);
    }

    private static getField(currentValue: OptionType[] | null, name: string, showLabel: boolean,
                            options: OptionType[], onChange: (selectedOption: any) => void, isMulti: boolean) {
        const labelText: string = TranslationService.translation(name);

        const label = <h5>
            <div className={"row"}>
                <div className={"col"}>
                    <label aria-label={name + ".label"}
                           htmlFor={name}>{labelText}</label>
                </div>
            </div>
        </h5>;
        const selectField = <div className={"row"}>
            <div className={"col newSelectContainer"}>
                <Select options={options}
                        isMulti={isMulti}
                        onChange={onChange}
                        value={currentValue}
                        aria-label={name + ".select.label"}

                />
            </div>
        </div>;
        if (!showLabel) {
            return <>
                {selectField}
            </>;
        }

        return <div>
            {label}
            {selectField}
        </div>;
    }

    private onChangeSelect(selectedOption: any) {
        if (this.props.onChange !== undefined) {
            let field = this.props.field;
            const name: string = field.name;
            let duplicationIndex: string = '0';

            let values: string | string[];
            if (Array.isArray(selectedOption)) {
                values = selectedOption.map((option) => option.value);
            } else {
                values = selectedOption.value;
            }
            this.props.onChange(name, values, duplicationIndex, true, field);
        }
    }


    private getCurrentValue(): OptionType[] | null {
        let propsValue = this.props.value;
        if (propsValue !== undefined && propsValue.length !== 0) {
            return propsValue.map((value) => {
                return {value: value, label: TranslationService.translation(value)}
            });
        }
        return null;
    }
}

export default PrehospitalFiltersDynamicSelect;
