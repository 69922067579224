import React from 'react';
import {KnarkbokService} from "./Knarkbokservice";
import "./DrugEventFocus.css"
import {BookId, DrugEvent} from "./models/Models";
import TranslationService from "../../infra/TranslationService";
import {jsPDF} from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";

interface props {
    KBService: KnarkbokService,
    closeFocus: () => void,
    book: BookId | undefined
    drugName: string,
    locationName: string
}

interface state {
    events: DrugEvent[]
}

class DrugEventFocus extends React.Component<props, state> {
    constructor(props: any) {
        super(props);
        this.state = {
            events: []
        }
    }

    async componentDidMount() {
        if (this.props.book) {
            let events = await this.props.KBService.getEventLogForDrug(this.props.book) as any; //TODO type
            this.setState({events: events.details.reverse()})
        }
    }

    async componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        if (this.props.book && prevProps.book?.id !== this.props.book?.id) {
            let events = await this.props.KBService.getEventLogForDrug(this.props.book) as any;
            this.setState({events: events.details.reverse()});
        }
    }

    render() {
        let data: any[];
        data = [];
        if (this.state.events) {
            //header
            data.push(<div className={"row m-0 p-0"} key={"header-thing"}>
                <div className={"col-3 m-0 p-0"}>
                    <div className={"row m-0 p-0"}>
                        <div className={"col-6 m-0 p-0"}><h6>Datum</h6></div>
                        <div className={"col-6 m-0 p-0"}><h6>Användare</h6></div>
                    </div>
                </div>
                <div className={"col-1 m-0 p-0"}><h6>Händelse</h6></div>
                <div className={"col-3 m-0 p-0"}>
                    <div className={"row m-0 p-0"}>
                        <div className={"col-4 m-0 p-0"}><h6>Specifikation</h6></div>
                        <div className={"col-8 m-0 p-0 text-left"}><h6>Kommentar</h6></div>
                    </div>
                </div>
                <div className={"col-1 m-0 p-0"}><h6>Antal</h6></div>
                <div className={"col-1 m-0 p-0"}><h6>Räknat saldo</h6></div>
                <div className={"col-1 m-0 p-0"}><h6>Beräknat saldo</h6></div>
                <div className={"col-2 m-0 p-0"}>
                    <div className={"row m-0 pl-3"}>
                        <div className={"col-6 m-0 p-0"}><h6>Avvikelse</h6></div>
                        <div className={"col-6 m-0 p-0"}><h6>Epost skickat</h6></div>
                    </div>
                </div>
            </div>)

            this.state.events.forEach((ev: DrugEvent, index) => {
                const grayed = index % 2 === 0 ? "grayed" : "";
                const redWarning = ev.warning ? " redwarning" : ""
                const comment: string | undefined = ev.comment;

                let commentStart: string = "";
                if (comment) {
                    const length: number = Math.min(7, comment.length);
                    const commentText: string = comment.substr(0, length);
                    let more: string = '';
                    if (comment.length > length) {
                        more = ' ...';
                    }
                    commentStart = commentText + more;
                }

                data.push(<div key={"drug-event" + index} className={"row m-0 p-0"}>
                    <div className={"col-3 m-0 p-0" + redWarning}>
                        <div className={"row m-0 p-0"}>
                            <div className={"col-6 m-0 p-0"}>{ev.time}</div>
                            <div className={"col-6 m-0 p-0"}>{ev.userFullName}</div>
                        </div>
                    </div>
                    <div
                        className={"col-1 m-0 p-0 " + grayed + redWarning}>{TranslationService.translation(ev.event.toString())}</div>
                    <div className={"col-3 m-0 p-0 " + grayed + redWarning}>
                        <div className={"row m-0 p-0"}>
                            <div
                                className={"col-4 m-0 p-0"}>{ev.additionalData ? TranslationService.translation(ev.additionalData) : ""}</div>
                            <div className={"col-8 m-0 p-0 text-left"}>{comment ?
                                <div title={comment} className={"hover-pointer"}
                                     onClick={(e) => DrugEventFocus.popup(e, comment)}>{commentStart}</div> : ""}</div>
                        </div>
                    </div>
                    <div className={"col-1 m-0 p-0 " + grayed + redWarning}>{ev.amount}</div>
                    <div className={"col-1 m-0 p-0 " + grayed + redWarning}>{ev.reportedTotal}</div>
                    <div className={"col-1 m-0 p-0 " + grayed + redWarning}>{ev.trueTotal}</div>
                    <div className={"col-2 m-0 p-0 " + grayed + redWarning}>
                        <div className={"row m-0 pl-3"}>
                            <div className={"col-6 m-0 p-0 " + grayed + redWarning}>{ev.balance}</div>
                            <div className={"col-6 m-0 p-0 " + grayed + redWarning}>{ev.warning ? "✓" : ""}</div>
                        </div>
                    </div>
                </div>)
            })
        }

        const grid = <div className={"container2 m-0 pl-0 pr-0"}>
            <div className={"row m-0 pt-2 pb-2"}>
                <div><h5 aria-label={'headline'}
                         className={"mt-0"}>
                    {this.props.drugName + ", " + this.props.locationName}
                </h5></div>
                <div className={"col m-0 pr-3 justify-content-end text-right"}>
                    <button aria-label={'close'}
                            className={"btn btn-close-focus btn-tage-default-cancel"}
                            onClick={() => this.props.closeFocus()}>
                        X
                    </button>
                </div>
            </div>
            <div className={"container2 m-0 pt-3 pl-0 kb-modal-scrollable"}>
                {data}
            </div>
            <div className={"row m-0 pt-4 pr-3"}>
                <div className={"col m-0 p-0  justify-content-end text-right"}>
                    <button aria-label={'print'}
                            onClick={() => this.createKbReport()}
                            className={"btn btn-tage-default-cancel"}>
                        Skriv ut
                    </button>
                </div>
            </div>
        </div>

        const foo = <div>
            <aside className="knarkbok-modal-background2" onClick={() => this.props.closeFocus()}/>
            <div className={"knarkbok-modal2 pr-3 justify-content-center pb-5"}>
                {grid}
            </div>
        </div>

        return (foo);
    }

    private createKbReport() {
        const doc = new jsPDF('l');

        let drugName = this.props.drugName;
        doc.text(`Förbrukningsjournal för ${drugName}, ${this.props.locationName}`, 14, 10);

        const body: string[][] = [];
        this.state.events.map((ev) => {
            body.push([ev.time, ev.userFullName, TranslationService.translation(ev.event.toString()), ev.additionalData ? TranslationService.translation(ev.additionalData) : "", ev.comment ? ev.comment + '' : '', ev.amount, ev.reportedTotal + '', ev.trueTotal + '', ev.balance, ev.warning ? "X" : ""])

            return null;
        })

        autoTable(doc, {
            headStyles: {fillColor: [82, 206, 255]},
            head: [['Datum', 'Användare', 'Händelse', 'Specifikation', 'Kommentar', 'Antal', 'Räknat Saldo', 'Beräknat Saldo', 'Avvikelse', 'Epost skickat']],
            body: body,
        })

        const date = new Date().toISOString();

        doc.save(`${drugName}-${this.props.locationName}-${date}.pdf`);
    }

    private static popup(e: React.MouseEvent<HTMLDivElement>, comment: string | undefined) {
        //TODO actual popup
        e.preventDefault()
        alert(comment);
    }
}

export default DrugEventFocus;

