import React from 'react';
import {BalanceType, BookId, DrugEventSummary, DrugEventSummaryStation} from "./models/Models";
import "./KnarkbokOverview.css"
import DrugEventFocus from "./DrugEventFocus";
import {KnarkbokService} from "./Knarkbokservice";
import {BackendContext} from "../../infra/BackendContext";
import {Organisation} from "../model/Organisation";

interface props {
    KBService: KnarkbokService,
    organisation: Organisation
}

interface state {
    grid: DrugEventSummary[]
    selectedBook: BookId | undefined
    showFocusWindow: boolean;
    headers: string[]
}


class KnarkbokOverview extends React.Component<props, state> {
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);

        this.state = {
            grid: [],
            selectedBook: undefined,
            showFocusWindow: false,
            headers: []
        }

    }

    async componentDidMount() {
        const orgId: string = this.props.organisation.organisationId;
        const grid = await this.props.KBService.getDrugEventSummaryStationForOrg(orgId) as any;

        if (grid && grid.overview && grid.headers) {
            this.setState({grid: grid.overview, headers: grid.headers});
        }
    }

    render() {
        const topics = this.getHeadersForGrid()
        let headers = <div className={"row ro2 m-0 pb-3 kb-overview-header"}>
            {
                topics.map((col, i) =>
                    <div key={col + " " + i}
                         className={"col-2"}>
                        <h5>
                            {col}
                        </h5>
                    </div>
                )
            }
        </div>;

        let selectedDrugName: string = '';
        let selectedLocationName: string = '';
        if (this.state.selectedBook) {
            for (let i: number = 0; i < this.state.grid.length; i++) {
                for (let j = 0; j < this.state.grid[i].cells.length; j++) {
                    if (this.state.grid[i].cells[j].bookId === this.state.selectedBook) {
                        selectedDrugName = this.state.grid[i].drug.name;
                        selectedLocationName = this.state.grid[i].cells[j].binderName
                    }
                }
            }
        }

        let focusWindow: React.JSX.Element | string;
        if (this.state.showFocusWindow) {
            focusWindow = <DrugEventFocus locationName={selectedLocationName}
                                          drugName={selectedDrugName}
                                          closeFocus={this.closeFocusWindow}
                                          book={this.state.selectedBook}
                                          KBService={this.props.KBService}/>;
        } else {
            focusWindow = "";
        }

        let grid = this.state.grid.map((row, index) => {
            const containsDeficiency = row.cells.find((cel) => cel.balanceType === BalanceType.deficiency);
            const containsExcess = row.cells.find((cel) => cel.balanceType === BalanceType.excess);
            let backgroundColoring = "";
            if (containsDeficiency) {
                backgroundColoring = "containsDeficiency"
            }
            if (containsExcess) {
                backgroundColoring = "containsExcess"
            }
            if (containsExcess && containsDeficiency) {
                backgroundColoring = "containsDeficiencyAndExcess"
            }
            return <div key={row.drug.name}
                        className={"row ro2 m-0 p-0 kb-overview-row " + (index % 2 === 0 ? "kb-grayed2" : "")}>
                <div className={"col-2 pt-2 pb-1 pl-3 " + backgroundColoring}>{` ${row.drug.name}`}</div>
                {
                    row.cells.map((col, index) => {
                        //the check for id === -1 below is based on the contract that BE sends -1 as id if it is not defined
                        return <div key={row.drug.name + " " + index}
                                    onClick={col.binderId.id !== -1 ? () => this.handleCellClick(col) : () => {
                                    }}
                                    className={"col-2 overview-cell pt-2 pb-1 " + KnarkbokOverview.getColumnName(col)}>
                            {((col.binderId === undefined || col.binderId.id !== -1) ? col.amount : "")}
                        </div>
                    })
                }
            </div>
        })

        /*
        const gridMaxWidth = 2400; //calc from number of stations

        const otherGrid = <div className={"kbo-1"}>
            <div className={"kbo-2"} style={{width: gridMaxWidth + "px"}}>
                fooo
            </div>
            <div>foo</div>
        </div>

         */

        return <div aria-label={'drug-journal-overview'}
                    className={"b-wrapper"}>
            <div className={"pt-3 overflow-b"}>
                {headers}
                <div className={"kb-overview-grid"}>
                    {grid}
                </div>
                <div id={"focus-window-wrapper"}>
                    {focusWindow}
                </div>
            </div>
        </div>;
    }

    private getHeadersForGrid(): string[] {
        const names: string[] = ["Läkemedel"];

        this.state.headers.forEach((header: string) => {
            names.push(header)
        })
        return names;
    }


    private static getColumnName(col: DrugEventSummaryStation) {
        if (col.balanceType === BalanceType.excess) {
            return "containsExcess"
        }
        if (col.balanceType === BalanceType.deficiency) {
            return "containsDeficiency"
        }
        return ""
    }

    private async handleCellClick(col: DrugEventSummaryStation) {
        const wrapper = document.getElementById("focus-window-wrapper");
        wrapper?.classList.add("drop-down-animation")
        const selectedBook: BookId = col.bookId;

        this.setState(
            {
                selectedBook: selectedBook,
                showFocusWindow: true
            }
        );
    }

    closeFocusWindow = () => {
        const wrapper = document.getElementById("focus-window-wrapper");
        wrapper?.classList.remove("drop-down-animation")
        this.setState({showFocusWindow: false})
    }
}

export default KnarkbokOverview;

