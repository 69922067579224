import React, {ReactElement} from 'react';
import {Field} from "../../register/v2/Action";
import {FieldProps} from "./FieldComponent";
import TranslationService from "../../../infra/TranslationService";
import Select from 'react-select'
import {fetchObjectOf} from "../../../infra/BackendService";
import "./Select.css";
import {OptionType} from "../../model/SelectOption";

interface props extends FieldProps {
    defaultSelection?: string
    frontendVersion?: string,
    actionName?: string,
    version?: number,
    value?: string,
    duplicationIndex?: string,
    onChange?: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void
}

interface state {
    selectedOption: OptionType | null
}

class StaticSelect extends React.Component<props, state> {
    constructor(props: Readonly<props>) {
        super(props);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.state = {
            selectedOption: null
        }
    }

    componentDidMount() {
        //TODO This is unnecessary the form works without it. it only exists to satisfy a test, static select needs looking at
        let currentValue = this.props.value;
        if (currentValue === undefined) {
            const actionName = this.props.actionName;
            const version = this.props.version;
            const name: string = this.props.field.name;
            let url = '/api/v1/last/' + actionName + '/' + version + "/" + name;
            fetchObjectOf<string>(url).then((item: string | undefined | null) => {
                if(this.props.onChange) {
                    if (item !== undefined && item !== null) {
                        this.props.onChange(name, this.getOptionFromValue(item).value, "0", true, this.props.field);
                        this.setState({selectedOption: this.getOptionFromValue(item)});
                    } else {
                        let options = this.getOptions();
                        this.props.onChange(name, options[0].value, "0", true, this.props.field);
                        this.setState({selectedOption: options[0]});
                    }
                }
            });
        }

    }

    render(): ReactElement {
        const name: string = this.props.field.name;
        const currentValue = this.getCurrentValue();
        const label: string = TranslationService.translation(this.props.field.name);

        let showLabel: boolean = true;
        if (this.props.field.showLabel !== undefined) {
            showLabel = this.props.field.showLabel;
        }

        let field = this.getField(label, showLabel, name, currentValue, this.onChangeSelect);

        return <div>
            {field}
        </div>
    }

    private getCurrentValue() {
        return this.props.value !== undefined ? this.getOptionFromValue(this.props.value) : this.state.selectedOption;
    }

    private getField(labelText: string,
                     showLabel: boolean,
                     name: string,
                     selectedOption: OptionType | null,
                     onChangeSelect: (selectedOption: any) => void): React.ReactFragment {
        const dropdownSize = "col newSelectContainer";


        const label = <h5>
            <div className={"row"}>
                <div className={"col"}>
                    <label aria-label={name + ".label"}
                           htmlFor={name}>{labelText}</label>
                </div>
            </div>
        </h5>;

        const field = <div className={"row"}>
            <div className={dropdownSize}>
                <Select id={name}
                        name={name}
                        aria-label={name + ".select.label"}
                        value={selectedOption}
                        onChange={onChangeSelect}
                        options={this.getOptions()}
                />
            </div>
        </div>;

        if (!showLabel) {
            return <>
                {field}
            </>;
        }

        return <div>
            {label}
            {field}
        </div>;
    }

    getOptionFromValue(option: string): OptionType {
        let translatedOption = TranslationService.translation(option);
        if (translatedOption.startsWith(this.props.field.name)) {
            let index = translatedOption.lastIndexOf('.');
            translatedOption = translatedOption.substring(index + 1);
        }
        return {
            value: option,
            label: translatedOption
        };
    }

    getOptions(): OptionType[] {
        let options: string[] = [];
        const src = this.props.field.options;
        if (src !== undefined) {
            src.forEach((option: string | Field) => {
                if (typeof option === 'string') {
                    const optionName: string = this.getOptionName(option);
                    options.push(optionName);
                }
            })
        }
        let fieldName = this.props.field.name;
        return options.map((option: string) => {
            let translatedOption = TranslationService.translation(option);
            if (translatedOption.startsWith(fieldName)) {
                let index = translatedOption.lastIndexOf('.');
                translatedOption = translatedOption.substring(index + 1);
            }
            return {
                value: option,
                label: translatedOption
            };
        });
    }

    private getOptionName(option: string): string {
        const name: string = this.props.field.name;

        return name + "." + option;
    }


    private onChangeSelect(selectedOption: any) {
        if (this.props.onChange !== undefined) {
            let field = this.props.field;
            const name: string = field.name;
            let duplicationIndex = this.duplicationIndex();

            let values: string | string[];
            if (Array.isArray(selectedOption)) {
                values = selectedOption.map((option) => option.value);
            } else {
                values = selectedOption.value;
            }

            this.props.onChange(name, values, duplicationIndex, true, field);
            this.setState({selectedOption: selectedOption});
        }
    }

    private duplicationIndex() {
        let duplicationIndex: string = '0';
        if (this.props.duplicationIndex !== undefined) {
            duplicationIndex = this.props.duplicationIndex;
        }
        return duplicationIndex;
    }

    getValueFromOption(option: OptionType | null) {
        if (option !== null) {
            return option.value;
        }
        return "";
    }

}

export default StaticSelect;
