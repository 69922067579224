import React, {ReactElement} from "react";
import {User} from "../model/User";
import DashboardSummary from "./DashboardTotal";
import DashboardEvent from "./DashboardEvent";
import "./UniversalDashboard.css";
import {EventBackendService} from "../register/v2/EventBackendService";
import {BackendContext} from "../../infra/BackendContext";


interface props {
    user: User
}

interface state {
    dashboardData?: {
        total: GraphTotalCard,
        eventStats: GraphCard[]
    }
}

export interface GraphCard {
    total: GraphTotalCard
    graphs: {
        filterOn: string,
        userGraph: {
            data: {
                columns: any[]
                type: string,
                empty?: { label: { text: string } }
            },

        }
        worldGraph: {
            data: {
                columns: any[]
                type: string,
                empty?: { label: { text: string } }
            },
        }
    }
}

export interface GraphTotalCard {
    totalLoggers: number,
    totalCount: number,
    userCount: number,
    avg: number,
    median: number,
    min: number,
    max: number,
    userBucket: number,
    graph: {
        data: {
            columns: any[]
            type: string,
            empty?: { label: { text: string } }
        },
        bar: {
            width: {
                ratio: any
            }
        }
    }
    headline: string,
    actionName?: string
}

class UniversalDashboard extends React.Component<props, state> {
    static contextType = BackendContext;

    constructor(props: props) {

        super(props);
        this.state = {
            dashboardData: undefined
        }
    }

    async componentDidMount() {
        const path = `/api/v1/universal-dashboard`
        const url = EventBackendService.getUrl2(path);
        const universalDashboardResponse = await this.context.get(url, {
            failure: "Fel uppstod"
        })

        if (universalDashboardResponse.data) {
            this.setState({
                dashboardData: {
                    total: universalDashboardResponse.data.total,
                    eventStats: universalDashboardResponse.data.eventStats,
                }
            })
        }
    }


    render(): ReactElement {
        let stats = <div
            className={"spinner p-5"}
            aria-label={"waiting for stats"}
        />;
        if (this.state.dashboardData) {
            stats = <div className={"dashboard-background-container"}>
            <div className={"container pt-2 dashboard-container"}>
                <div className={"row"}>
                    <DashboardSummary data={this.state.dashboardData.total}
                                      firstName={this.props.user.firstName}></DashboardSummary>
                </div>
                <div className={"row"}>
                    {
                        this.state.dashboardData.eventStats.map((event) => <DashboardEvent key={event.total.headline}
                                                                                           data={event}
                                                                                           firstName={this.props.user.firstName}/>)
                    }
                </div>
            </div>
            </div>;
        }

        return stats
    }

}


export default UniversalDashboard;
