import {Resolution} from "../../infra/Constants";
import {getUserSettings} from "../../infra/BackendService";


class UserSessionUtilService {
    private static dateFormat: string;
    private static email: string;

    static getResolution(): Resolution {
        return UserSessionUtilService.getDeviceResolution();
    };

    static getDateFormat(): string {
        return UserSessionUtilService.dateFormat;
    };

    static getUserEmail(): string {
        return UserSessionUtilService.email;
    }

    static async init() {
        const uri = '/api/v1/user-settings';
        try {
            const fetchedSettings: UserSettings = await getUserSettings(uri);
            UserSessionUtilService.dateFormat = fetchedSettings.dateFormat || "yyyy-MM-dd";
            UserSessionUtilService.email = fetchedSettings.email || "";
        } catch (e) {
            console.error(e);
        }
    }

    static getDeviceResolution(): Resolution {
        const width: number = window.innerWidth;
        let device: Resolution;
        if (width >= 1200) {
            device = Resolution.Desktop;
        } else {
            device = Resolution.Mobile;
        }

        return device;
    }
}

export default UserSessionUtilService;

export interface UserSettings {
    dateFormat?: string,
    email?: string
}

