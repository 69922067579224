import {getSecurityToken} from "./SecurityToken";
import {logout} from "./Logout";
import {UserSettings} from "../components/model/UserSessionUtilService";
import {BackendService2} from "./BackendService2";

let host: string;
if (process.env.REACT_APP_LOCALHOST === undefined) {
    host = '';
} else {
    host = process.env.REACT_APP_LOCALHOST;
}

/**
 * Get a T from backend or undefined if an error ocurred
 * @param uri the resource without protocol and host
 */
export async function fetchObjectOf<T>(uri: string): Promise<T | undefined> {
    const url = host + uri;

    try {
        const response: Response = await fetch(url, {
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include'
        });

        if (!response.ok) {
            return undefined;
        } else {
            return await response.json();
        }
    } catch (err) {
        console.error(err);
        return undefined;
    }
}

//todo use this everywhere?
export async function fetchObjectOfThrowsError<T>(uri: string): Promise<T | undefined> {
    const url = host + uri;

    return fetch(url, {
        headers: {
            'X-Custom-header': getSecurityToken()
        },
        credentials: 'include'
    }).then(response => {
        // return something the frontend can use as an error description when an error has occurred
        //  a json with the stuff from the backend if the return ode is 400

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return response.json();
    }).then();
}

export async function fetchText(uri: string): Promise<string | undefined> {
    const url = host + uri;

    try {
        const response: Response = await fetch(url, {
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include'
        });

        if (!response.ok) {
            return undefined;
        } else {
            return await response.text();
        }
    } catch (err) {
        console.error(err);
        return undefined;
    }
}

/**
 * Get a T from backend or undefined if an error ocurred
 * @param uri the resource without protocol and host
 */
export async function unsecuredFetch<T>(uri: string): Promise<T | undefined> {
    const url = host + uri;

    try {
        const response: Response = await fetch(url);
        if (!response.ok) {
            return undefined;
        } else {
            return await response.json();
        }
    } catch (err) {
        console.error(err);
        return undefined;
    }
}

/**
 * Get an array of T from backend or an empty array if an error ocurred
 * @param uri the resource without protocol and host
 */
export async function unsecuredFetchArrayOf<T>(uri: string): Promise<T[]> {
    const url = host + uri;

    try {
        const response: Response = await fetch(url);
        if (!response.ok) {
            return [];
        } else {
            return await response.json();
        }
    } catch (err) {
        console.error(err);
        return [];
    }
}

/**
 * Get a list of T from backend
 * @param uri the resource without protocol and host
 */
export async function fetchArrayOf<T>(uri: string): Promise<T[]> {
    const url = host + uri;

    try {
        const response: Response = await fetch(url, {
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include'
        });

        if (!response.ok) {
            return [];
        } else {
            return await response.json();
        }
    } catch (err) {
        console.error(err);
        return [];
    }
}

/**
 * Get user settings like dateformat from backend
 *
 * @param uri the resource without protocol and host
 */
export async function getUserSettings(uri: string): Promise<UserSettings> {
    const url = host + uri;

    try {
        const response: Response = await fetch(url, {
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include'
        });

        if (!response.ok) {
            console.error(response.status + ", " + response.statusText);
        } else {
            return JSON.parse(await response.json());
        }
    } catch (err) {
        console.error("error while fetching user settings", err)
    }
    return {
        dateFormat: "yyyy-MM-dd"
    }
}


/**
 * Get user kb rights
 *
 * @param uri the resource without protocol and host
 */
export async function getUserKbRights(uri: string): Promise<any[]> {
    const url = host + uri;

    try {
        let getResults = await BackendService2.get(url);
        return getResults as any[];
    } catch (err) {
        console.error("error while fetching user kb rights", err)
    }
    return [];
}

/**
 * Get a Map<string, string> from backend
 *
 * @param uri the resource without protocol and host
 */
export async function fetchMapOfStrings(uri: string): Promise<Map<string, string>> {
    const url = host + uri;

    try {
        const response: Response = await fetch(url, {
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include'
        });

        if (!response.ok) {
            logout();
            return new Map();
        } else {
            const json = await response.json();
            const map: Map<string, string> = new Map();
            for (const key in json) {
                if (json.hasOwnProperty(key)) {
                    const value = json[key];
                    map.set(key, value);
                }
            }

            return map;
        }
    } catch (err) {
        logout();
        return new Map();
    }
}

export async function postObjectOf<T, K>(payload: T, uri: string): Promise<K> {
    const url = host + uri;

    return fetch(url, {
        method: "POST",
        headers: {
            'X-Custom-header': getSecurityToken()
        },
        credentials: 'include',
        body: JSON.stringify(payload)
    }).then(response => {
        // return something the frontend can use as an error description when an error has occurred
        //  a json with the stuff from the backend if the return ode is 400

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return response.json();
    }).then();
}


export async function unsecuredPost<T, K>(payload: T, uri: string): Promise<K> {
    const url = host + uri;

    return fetch(url, {
        method: "POST",
        body: JSON.stringify(payload)
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return response.json();
    }).then();
}

export async function put<T, K>(payload: T, uri: string): Promise<K> {
    const url = host + uri;

    return fetch(url, {
        method: "PUT",
        headers: {
            'X-Custom-header': getSecurityToken()
        },
        credentials: 'include',
        body: JSON.stringify(payload)
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText)
        }
    }).then();
}

export async function deleteItem<T>(uri: string): Promise<T> {
    const url = host + uri;

    return fetch(url, {
        method: "DELETE",
        headers: {
            'X-Custom-header': getSecurityToken()
        },
        credentials: 'include',
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return response.json();
    }).then();
}
