import React, {ReactElement} from 'react';
import {Action, Field} from "../../register/v2/Action";
import {FieldComponent, FieldError} from "./FieldComponent";
import TranslationService from "../../../infra/TranslationService";
import Value from "../../register/v2/Value";
import {createMultipleValues, isMultipleValuesValid} from "./FieldUtil";
import {uuidv4} from "../../register/v2/Uuid";
import {ALL_ALTERNATIVES, DESKTOP, MOBILE, STATISTICS, V3} from "../../../infra/Constants";
import "./Expanding.css";
import {getField, getFields} from "../../register/v2/Form";
import ConditionalField, {ConditionalProps, ConditionalState} from "./ConditionalField";
import {isEqualDisregardingDuplicationMarkers} from "./DuplicationUtility";
import EventNG from "../../register/v3/Event";
import getRegisterFieldNG from "../../register/v3/FieldFactory";
import getStatisticsFieldNG from "../../statistics/v3/FieldFactory";
import {GraphOptions} from "../../statistics/v2/GraphOptions";

interface props extends ConditionalProps {
    device: string,
    enableAllOptions: boolean,
    frontendVersion?: string,
    value?: string[],
    duplicationIndex?: string,
    onChange?: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void,
    event?: EventNG,
    action?: Action,
    user?: any
}

interface state extends ConditionalState {
    options: (string | Field)[];
    values: string[]
}

class Checkbox extends ConditionalField<props, state> implements FieldComponent {
    fieldReferences: Map<string, any> = new Map<string, any>();

    constructor(props: Readonly<props>) {
        super(props);

        let options: (string | Field)[] = [];

        if (this.props.field.options !== undefined) {
            this.props.field.options.forEach((option: string | Field) => {
                options.push(option);
            });
        }

        let enableAllOptions: boolean = false;
        if (this.props.enableAllOptions !== undefined) {
            enableAllOptions = this.props.enableAllOptions;
        }

        const defaultValues: string[] = [];
        if (enableAllOptions) {
            options.push(ALL_ALTERNATIVES);
            const optionName: string = this.getOptionName(ALL_ALTERNATIVES);
            defaultValues.push(optionName);
        }

        if (this.props.field.defaultValue !== undefined) {
            const defaultValue = this.props.field.defaultValue;
            defaultValues.push(defaultValue);
        }

        this.state = {
            ...this.state,
            options: options,
            values: defaultValues
        }
    }

    render(): ReactElement {
        const name: string = this.props.field.name;
        const ariaLabel: string = name;
        const label: string = TranslationService.translation(this.props.field.name);
        const columns = this.columns();
        const currentValues = this.getCurrentValues();
        const availableOptions = this.state.options;

        const options = this.getOptions(currentValues, availableOptions);
        let showLabel: boolean = true;
        if (this.props.field.showLabel !== undefined) {
            showLabel = this.props.field.showLabel;
        }

        const field = Checkbox.getField(ariaLabel, label, showLabel, columns, options);

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            return <div>
                {field}
            </div>
        } else {
            return this.renderField(field, name);
        }
    }

    private getCurrentValues(): string[] {
        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            if (this.props.value !== undefined) {
                return this.props.value;
            } else {
                return [];
            }
        } else {
            return this.state.values;
        }
    }

    private static getField(ariaLabel: string,
                            labelText: string,
                            showLabel: boolean,
                            columns: { columns: number },
                            options: {} | React.ReactNodeArray) {
        const label = <h5>
            <label data-testid={ariaLabel}
                   aria-label={ariaLabel}>
                {labelText}
            </label>
        </h5>;

        const optionsFragment = <div style={columns}>
            {options}
        </div>;

        if (!showLabel) {
            return <>
                {optionsFragment}
            </>;
        }

        return <>
            {label}
            {optionsFragment}
        </>;
    }

    private columns() {
        let columns = {columns: 1};
        const optionsLayout = this.props.field.optionsLayout;
        if (optionsLayout !== undefined) {
            const device = this.props.device;

            if (device === DESKTOP) {
                columns.columns = optionsLayout.desktop.columns;
            }

            if (device === MOBILE) {
                columns.columns = optionsLayout.mobile.columns;
            }
        }
        return columns;
    }

    private getOptions(currentValue: (string | Field)[], availAbleOptions: (string | Field)[]): React.ReactFragment {
        const fieldName: string = this.props.field.name;

        if (availAbleOptions.length > 0) {
            return availAbleOptions.map((option: string | Field) => {
                if (typeof option === 'string') {
                    const optionName: string = this.getOptionName(option);
                    return this.getOption(fieldName, optionName);
                }

                if (Checkbox.isCurrentlySelected(currentValue, option.name)) {
                    return this.makeExpandedField(fieldName, option);
                }

                return this.makeUnexpandedCheckbox(fieldName, option);
            });
        } else {
            return <div/>;
        }
    }

    private makeUnexpandedCheckbox(name: string, option: Field) {
        return this.getOption(name, option.name);
    }

    private static isCurrentlySelected(currentValue: (string | Field)[], name: string): boolean {
        for (const value of currentValue) {
            if (typeof value === 'string') {
                if (isEqualDisregardingDuplicationMarkers(value, name)) {
                    return true;
                }
            } else {
                if (isEqualDisregardingDuplicationMarkers(value.name, name)) {
                    return true;
                }
            }
        }

        return false;
    }

    private getOption(fieldName: string, optionName: string): React.ReactFragment {
        const id: string = uuidv4();

        let labelText: string = TranslationService.translation(optionName);
        if (optionName.endsWith(ALL_ALTERNATIVES)) {
            labelText = TranslationService.translation(ALL_ALTERNATIVES);
        }
        const currentValues = this.getCurrentValues();
        const checked: boolean = Checkbox.isCurrentlySelected(currentValues, optionName);

        return <span key={optionName}
                     className={"no-line-break"}
                     onChange={() => this.onChange(optionName)}
        >
            <input type={"checkbox"}
                   name={fieldName}
                   id={id}
                   checked={checked}
                   readOnly={true}
                   aria-label={optionName}/>
            <label className={"mb-0"} htmlFor={id}>{labelText}</label>
        <br/>
        </span>;
    }

    private onChange(option: string): void {
        let current: string[] = this.getCurrentValues();
        if (option.endsWith(ALL_ALTERNATIVES)) {
            if (Checkbox.isCurrentlySelected(current, option)) {
                current = [];
                this.props.field.options?.forEach((option: string | Field) => {
                    let optionName: string;
                    if (typeof option === 'string') {
                        optionName = this.getOptionName(option);
                    } else {
                        optionName = this.getOptionName(option.name);
                    }
                    if (!optionName.endsWith(ALL_ALTERNATIVES)) {
                        current.push(optionName);
                    }
                });
            } else {
                current = [];
                current.push(option);
            }
        } else {
            if (Checkbox.isCurrentlySelected(current, option)) {
                current = current.filter((value) => value !== option);
                const enableAllOptions = this.props.enableAllOptions;
                if (current.length === 0 && enableAllOptions) {
                    option = this.getOptionName(ALL_ALTERNATIVES);
                    current.push(option);
                }
            } else {
                const optionName: string = this.getOptionName(ALL_ALTERNATIVES);
                current = current.filter((value) => value !== optionName);

                current.push(option)
            }
        }

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            if (this.props.onChange) {
                let field = this.props.field;
                const name: string = field.name;
                let duplicationIndex: string = '0';
                if (this.props.duplicationIndex !== undefined) {
                    duplicationIndex = this.props.duplicationIndex;
                }

                this.props.onChange(name, current, duplicationIndex, true, field);
            }
        } else {
            this.setState({values: current});
        }
    }

    private getOptionName(option: string): string {
        const name: string = this.props.field.name;

        if (option.startsWith(name)) {
            return option;
        }

        return name + "." + option;
    }

    values(): Value[] {
        const name: string = this.props.field.name;
        let currentValues: string[] = this.getCurrentValues();

        if (this.isValid().valid) {
            const allAlternatives: string = this.getOptionName(ALL_ALTERNATIVES);
            if (Checkbox.isCurrentlySelected(currentValues, allAlternatives)) {
                return [];
            }

            if (currentValues.length > 0) {
                let values: Value[] = createMultipleValues(name, currentValues);

                this.fieldReferences.forEach((currentValues: any) => {
                    const fieldComponent: FieldComponent = currentValues.current;
                    if (fieldComponent !== null) {
                        const vals: Value[] = fieldComponent.values();
                        values = values.concat(vals);
                    }
                });

                return values
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    isValid(): FieldError {
        const name = this.props.field.name;
        const value = this.getCurrentValues();
        const mandatory = this.props.field.mandatory;

        return isMultipleValuesValid(name, value, mandatory);
    }

    clear(): void {
        let visible = this.isConditionalfield();
        const defaultValues: string[] = [];
        if (this.props.field.defaultValue !== undefined) {
            const defaultValue = this.props.field.defaultValue;
            defaultValues.push(defaultValue);
        }

        this.setState({
            values: defaultValues,
            visible: visible
        })
    }

    set(values: Value[]): void {
        const name = this.props.field.name;
        values.forEach((value: Value) => {
            if (value.fieldName === name) {
                this.setState(
                    {
                        values: value.values
                    }, () => {
                        this.fieldReferences.forEach((reference: any) => {
                            if (reference !== null) {
                                const expanded: FieldComponent = reference.current;
                                if (expanded) {
                                    expanded.set(values);
                                }
                            }
                        });
                    });
            }
        });
    }

    private makeExpandedField(fieldName: string, option: Field): React.ReactFragment {
        const parentOptionName = option.name;
        const parent: React.ReactFragment = this.getOption(fieldName, parentOptionName);
        const device = this.props.device;
        const cssId = uuidv4();

        let children: React.ReactFragment[] = [];
        if (option.options !== undefined) {
            const frontendVersion = this.props.frontendVersion;
            if (frontendVersion !== undefined && frontendVersion === V3) {
                let user: any = {
                    dateFormat: 'yyyy-MM-dd'
                }
                if (this.props.user !== undefined) {
                    user = this.props.user;
                }

                if (this.props.onChange !== undefined && this.props.event !== undefined && this.props.action !== undefined) {
                    const onChange = this.props.onChange;
                    const event: EventNG = this.props.event;
                    const action: Action = this.props.action;
                    let child: React.ReactFragment;

                    if (action.type === STATISTICS) {
                        const graphOptions: GraphOptions = {
                            groupBy: ''
                        };

                        child = getStatisticsFieldNG(option, graphOptions, event, '0', action, user, onChange);
                    } else {
                        child = getRegisterFieldNG(option, event, onChange, action, user);
                    }

                    children.push(child);
                }
            } else {
                const ref: any = React.createRef();
                this.fieldReferences.set(option.name, ref);
                const shareMyValue = this.props.shareMyValue;
                const child = getField(option, this.fieldReferences, device, "yyyy-mm-dd", shareMyValue);
                children.push(child);
            }
        }

        if (option.fields !== undefined && option.fields.length > 0) {
            const frontendVersion = this.props.frontendVersion;
            if (frontendVersion !== undefined && frontendVersion === V3) {
                if (this.props.onChange !== undefined && this.props.event !== undefined && this.props.action !== undefined) {
                    const onChange = this.props.onChange;
                    const event: EventNG = this.props.event;
                    const action: Action = this.props.action;
                    let user: any = {
                        dateFormat: 'yyyy-MM-dd'
                    }
                    if (this.props.user !== undefined) {
                        user = this.props.user;
                    }

                    children = option.fields.map((f: Field) => {
                        let duplicationIndex: string = '0';
                        if (this.props.duplicationIndex !== undefined) {
                            duplicationIndex = this.props.duplicationIndex;
                        }

                        let visibleField: React.ReactFragment;

                        if (this.props.onChange !== undefined && this.props.event !== undefined && this.props.action !== undefined && this.props.action.type === STATISTICS) {
                            const onChange = this.props.onChange;
                            const event: EventNG = this.props.event;
                            const action: Action = this.props.action;
                            const graphOptions: GraphOptions = {
                                groupBy: ''
                            };

                            visibleField = getStatisticsFieldNG(f, graphOptions, event, '0', action, user, onChange);
                        } else {
                            visibleField = getRegisterFieldNG(f, event, onChange, action, user);
                        }
                        let key = f.name + '-' + duplicationIndex;
                        return <div key={key}>{visibleField}</div>;
                    });
                }
            } else {
                const shareMyValue = this.props.shareMyValue;
                children = option.fields.map(f => {
                    return getFields(f, this.fieldReferences, device, "yyyy-mm-dd", shareMyValue);
                });
            }
        }

        return <div key={parentOptionName}>
            {parent}
            <div id={cssId}
                 onClick={() => this.changeClassAfterDelay(cssId)} className={"expandingSpacerWrapper"}>
                <div className={"pl-4 fade-in"}>
                    {children}
                </div>
            </div>
        </div>
    }

    private changeClassAfterDelay(cssId: string) {
        setTimeout(function () {
            const wanted = document.getElementById(cssId)
            wanted?.classList.add("allowOverflow")
        }, 350);
    }
}

export default Checkbox;
