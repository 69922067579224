import React, {ReactElement, ReactFragment} from "react";
import ReportTable, {Report} from "../reports/v1/ReportTable";
import {fetchArrayOf, fetchObjectOf} from "../../infra/BackendService";
import TranslationService from "../../infra/TranslationService";
import PrehospitalMissionReport from "./PrehospitalMissionReport";
import RegisterResultFailureMessage from "../register/v2/RegisterResult";
import {uuidv4} from "../register/v2/Uuid";
import {Organisation} from "../backoffice/roles/v3/Organisation";
import ExportEventsButton from "./ExportEventsButton";


interface props {
    firstName: string,
    hasExportRight: boolean
    hasExportOrganisationEventsRight: boolean,
    hasWorldAirwayExportRight?: boolean
    hasWorldPrehospitalExportRight?: boolean
}

interface state {
    aggregatedMissions: Report | undefined,
    showFailureMessage: boolean
    additionalErrors: string[],
    organisationsForExport: Organisation[],
    exportFinished: boolean | undefined
}


class PrehospitalHome extends React.Component<props, state> {
    constructor(props: props) {
        super(props);
        this.state = {
            aggregatedMissions: undefined,
            showFailureMessage: false,
            additionalErrors: [],
            organisationsForExport: [],
            exportFinished: undefined,
        }
    }

    render(): ReactElement {
        const welcome = TranslationService.translation("welcome");

        let welcomeSection = <div>
            <h2 className={"strong pb-4"}>{welcome} {this.props.firstName}!</h2>
        </div>

        const dashboardHeader = TranslationService.translation("prehospital-response-unit.dashboard");
        let slaDashboard = <div>
            <h3 className={"strong pb-4"}>{dashboardHeader}</h3>
        </div>

        let aggregatedMissions = this.state.aggregatedMissions;
        let aggregateMissionsHeader = "Översikt";
        let aggregatedMissionsReport = this.getReport(aggregatedMissions, "aggregated missions", aggregateMissionsHeader, true, PrehospitalHome.getLegend(false));

        let lastMissionsReport = <PrehospitalMissionReport/>;

        let organisations = this.state.organisationsForExport;
        let organisationExportButtons: React.JSX.Element[] = [];
        if (organisations.length > 0) {
            organisationExportButtons = organisations.map((organisation) => {
                let buttonText = 'Exportera ' + organisation.name + 's registreringar';
                return <div key={uuidv4()}
                            className={"pb-4"}>
                    <ExportEventsButton hasExportRight={true}
                                        buttonText={buttonText}
                                        showFailureMessage={this.showFailureMessage}
                                        scope={'organisation'}
                                        actionName={'prehospital-response-unit'}
                                        version={0}
                                        organisationId={organisation.id}
                    />
                </div>;
            })
        }


        let snackbar: React.JSX.Element;
        let failureModal: React.JSX.Element = <div/>;
        const failureMessage = ["Något gick tokigt! Vi jobbar på att lösa det."];

        if (this.state.showFailureMessage) {
            failureModal =
                <RegisterResultFailureMessage message={failureMessage} hideFailureMessage={this.hideFailureMessage}/>;
        }
        let hasErrors = this.state.additionalErrors !== undefined && this.state.additionalErrors.length > 0;
        snackbar = hasErrors ? <div>
            <div>
                {TranslationService.translation("some data could not be exported")}
            </div>
            <div>{TranslationService.translation("prehospital-response-unit.missing.events.details")}</div>
        </div> : <div/>;

        let additionalErrors;

        if (hasErrors) {
            additionalErrors = this.state.additionalErrors.map((error) =>
                <li key={uuidv4()}>{error}</li>
            );
        }
        let spinner = <div/>;
        if (this.state.exportFinished !== undefined && !this.state.exportFinished) {
            spinner = <div
                className={"spinner"}
                aria-label={"waiting for organisation events"}
            />;
        }

        return <div className={"container"}>
            {welcomeSection}
            {slaDashboard}
            {aggregatedMissionsReport}
            {organisationExportButtons}
            {spinner}
            {snackbar}
            <ol type="1">{additionalErrors}</ol>
            {lastMissionsReport}
            {failureModal}
        </div>
    }

    showFailureMessage = () => {
    }

    private getReport(aggregatedMissions: Report | undefined, reportName: string, header: string, horizontalScrolling: boolean, legend: ReactFragment) {
        let translatedHeader = TranslationService.translation(header)
        let aggregatedMissionsReportTable = aggregatedMissions !== undefined ? <ReportTable report={aggregatedMissions}
                                                                                            horizontalScrolling={horizontalScrolling}
                                                                                            legend={legend}/>
            : <div
                className={"spinner"}
                aria-label={"waiting for " + reportName}
            />;
        return <div aria-label={reportName + " report"}>
            <h3 className={"strong pb-2 pt-4"}>{translatedHeader}</h3>
            {aggregatedMissionsReportTable}
            <hr/>
        </div>;
    }

    static getLegend(hasLegend: boolean) {
        if (hasLegend) {
            const int = TranslationService.translation("prehospital-response-unit.patient.airway_attempt.device.ett") + " " + TranslationService.translation("prehospital-response-unit.patient.airway_attempt.device.ett.oral");
            const ca = TranslationService.translation("prehospital-response-unit.patient.hr.ca");
            const rosc = TranslationService.translation("prehospital-response-unit.patient.rosc");
            const thoracostomy = TranslationService.translation("prehospital-response-unit.patient.breathing_treatment.active.decompression.thoracostomy");
            const cvk = TranslationService.translation("prehospital-response-unit.patient.circulation_treatment.active.vascular_access") + " " + TranslationService.translation("prehospital-response-unit.patient.circulation_treatment.active.vascular_access.cvk");
            const pt = TranslationService.translation("prehospital-response-unit.category_sos.trauma") + " " + TranslationService.translation("prehospital-response-unit.category_sos.trauma.penetrating");
            const deviation = TranslationService.translation("prehospital-response-unit.result.lesson.yes.complication");

            const legendHeader = TranslationService.translation("prehospital-response-unit.dashboard.legend.header");
            return <div>
                <div className="strong" aria-label="legendHeader">{legendHeader}</div>
                <div><span className={"intubationFlag"}>INT</span>: {int}</div>
                <div><span className={"cardiacArrestFlag"}>CA</span>: {ca}</div>
                <div><span className={"roscFlag"}>R</span>: {rosc}</div>
                <div><span className={"thoracostomyFlag"}>TS</span>: {thoracostomy}</div>
                <div><span className={"cvkFlag"}>CVK</span>: {cvk}</div>
                <div><span className={"penetratingTraumaFlag"}>PT</span>: {pt}</div>
                <div><span className={"deviationFlag"}>AVV!</span>: {deviation}</div>
            </div>
        } else return <div></div>;


    }

    componentDidMount() {
        let aggregateUrl: string = "/api/v1/reports/prehospital/missions/aggregate"

        fetchObjectOf<Report>(aggregateUrl).then((aggregateMissionsReport) => {
            this.setState({aggregatedMissions: aggregateMissionsReport})
        });

        if (this.props.hasExportOrganisationEventsRight) {
            let organisationsForWhichExportIsPossibleUrl = "/api/v1/organisations/export-right/prehospital-response-unit/0";
            fetchArrayOf<Organisation>(organisationsForWhichExportIsPossibleUrl).then((organisations: Organisation[]) => {
                this.setState({organisationsForExport: organisations})
            });
        }
    }

    hideFailureMessage = () => {
        this.setState({showFailureMessage: false})
    }
}


export default PrehospitalHome;
