import React from 'react';
import './Tage.css';
import TranslationService from "./infra/TranslationService";
import './Public.css';
import {getSecurityToken, refreshSecurityToken} from "./infra/SecurityToken";
import UserSessionUtilService from "./components/model/UserSessionUtilService";
import Content from "./components/Content";
import Footer from "./components/Footer";
import {UserData} from "./components/model/UserData";
import {Organisation} from "./components/model/Organisation";
import {User} from "./components/model/User";
import {NavBar} from "./components/model/NavBar";
import FullNavBarHub from "./components/navbar/FullNavBarHub";

const REFRESHAPI = process.env.REACT_APP_LOCALHOST + "/api/v1/refresh";

function refresh() {
    refreshSecurityToken();
}

interface props {
    user: User
    updateNavBar: (navBar: NavBar) => void
    setCurrentOrganisation: (organisation: Organisation | undefined) => void
    userData: UserData
    currentOrganisation?: Organisation
    logoutUser: (reason?: string) => void
    refreshToken: (token: string) => void
    refreshThreshold: number
    securityToken: string
    loggedIn: boolean,
    setPath: (path: string) => void
}

interface state {
    userData: UserData
    loggedIn: boolean
    action: string
    statsAction: string
    actionDefinitions: any[]
}

class Tage extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.setAction = this.setAction.bind(this);
        this.setStatsAction = this.setStatsAction.bind(this);
        this.refreshExpiredToken = this.refreshExpiredToken.bind(this);

        const userData: UserData = this.props.userData;
        const loggedIn: boolean = this.props.loggedIn;

        this.state = {
            userData: userData,
            loggedIn: loggedIn,
            action: '',
            statsAction: '',
            actionDefinitions: []
        }
    }

    setAction(action: string): void {
        this.setState({action: action});
    }

    setStatsAction(statsAction: string): void {
        this.setState({statsAction: statsAction});
    }

    refreshExpiredToken() {
        if (this.props.userData !== undefined) {
            let refreshThreshold = this.props.userData.refreshThreshold;

            if (!refreshThreshold) {
                refreshThreshold = 1;
            }

            const wholeToken = this.props.securityToken.split(";");
            const validToStr = wholeToken[1];

            let validTo: number = Number(validToStr);
            if (isNaN(validTo)) {
                validTo = 1;
            }

            const browserTime = new Date().getTime();
            const browserTimeAdjusted: number = browserTime + refreshThreshold * 1000 * 60 * 60; // the threshold is assumed to be in hours

            if (browserTimeAdjusted > validTo) {
                fetch(REFRESHAPI, {
                    headers: {
                        'X-Custom-header': getSecurityToken()
                    },
                    credentials: 'include',
                    method: "GET"
                })
                    .then(res => res.json())
                    .then((data) => {
                        if (data.not !== undefined) {
                            if (data.not === "ok") {
                                const reason = TranslationService.translation("logout after inactivity");
                                this.props.logoutUser(reason);
                            } else {
                                this.props.refreshToken(data);
                            }
                        } else {
                            this.props.refreshToken(data);
                        }
                    }).catch(console.log);
            }
        }
    }

    async componentDidMount(): Promise<void> {
        this.refreshExpiredToken();
        await UserSessionUtilService.init();

        if (document !== null) {
            document.addEventListener("click", refresh);
            document.addEventListener("touchstart", refresh);
            document.addEventListener("keydown", refresh);
            window.addEventListener("beforeunload", () => {
                sessionStorage.clear()
            });
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener("click", refresh);
        document.removeEventListener("touchstart", refresh);
        document.removeEventListener("keydown", refresh);
    }

    render(): React.JSX.Element {
        const currentOrganisation: Organisation | undefined = this.props.currentOrganisation;
        const userData: UserData = this.state.userData;
        const user = this.props.user;
        const updateNavBar = this.props.updateNavBar;
        const currentOrg = this.props.setCurrentOrganisation;

        const navBar: React.JSX.Element = <FullNavBarHub user={user}
                                                         nav={userData.navBar}
                                                         updateNavBar={updateNavBar}
                                                         setCurrentOrganisation={currentOrg}
                                                         userData={userData}
                                                         refreshExpiredToken={this.refreshExpiredToken}
                                                         securityToken={this.props.securityToken}
                                                         setAction={this.setAction}
                                                         setPath={this.props.setPath}
                                                         setStatsAction={this.setStatsAction}
        />

        return <div aria-label={'LogEze'}>
            {navBar}
            <Content userData={userData}
                     refreshExpiredToken={this.refreshExpiredToken}
                     securityToken={this.props.securityToken}
                     action={this.state.action}
                     statsAction={this.state.statsAction}
                     currentOrganisation={currentOrganisation}
            />
            <Footer/>
        </div>;
    }
}

export default Tage;
