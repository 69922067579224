import React, {ReactElement} from 'react';
import {Action, Field} from "../../register/v2/Action";
import {FieldComponent, FieldError} from "./FieldComponent";
import TranslationService from "../../../infra/TranslationService";
import Value from "../../register/v2/Value";
import {createSingleValue, isSingleValueValid} from "./FieldUtil";
import {DESKTOP, MOBILE, NOT_REGISTERED, STATISTICS, V3} from "../../../infra/Constants";
import {getField, getFields} from "../../register/v2/Form";
import getFieldNG from "../../register/v3/FieldFactory";
import EventNG from "../../register/v3/Event";
import {findValue, getBreakpoints, getNumberOfSpacers} from "./ExpandingSpacer";
import Register from "../../register/v2/Register";
import "./Radio.css";
import "./Expanding.css";
import ConditionalField, {ConditionalProps, ConditionalState} from "./ConditionalField";
import {uuidv4} from "../../register/v2/Uuid";
import {isEqualDisregardingDuplicationMarkers} from "./DuplicationUtility";

interface props extends ConditionalProps {
    device: string,
    frontendVersion?: string,
    value?: string,
    duplicationIndex?: string,
    onChange?: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void,
    event?: EventNG,
    action?: Action,
    user?: any
}

interface state extends ConditionalState {
    value: string
}

class Radio extends ConditionalField<props, state> implements FieldComponent {
    fieldReferences: Map<string, any> = new Map<string, any>();

    constructor(props: Readonly<props>) {
        super(props);
        let defaultValue = "";
        if (this.props.field.defaultValue !== undefined) {
            defaultValue = this.props.field.defaultValue;
        }

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            if (this.props.value !== undefined) {
                defaultValue = this.props.value;
            } else {
                defaultValue = '';
            }
        }

        this.state = {
            ...this.state,
            value: defaultValue
        }
    }

    render(): ReactElement {
        const name: string = this.props.field.name;
        const headLineLabel: string = name;
        const label: string = TranslationService.translation(name);
        const layoutLabel = name + ".layout";
        const columns = this.columns();
        const currentValue: string = this.getCurrentValue();
        const options = this.getOptions(currentValue);

        let mandatory: boolean = false;
        if (this.props.field.mandatory !== undefined) {
            mandatory = this.props.field.mandatory
        }
        const nonMandatoryOption = this.getNonMandatoryOption(name, currentValue, mandatory);

        let showLabel: boolean = true;
        if (this.props.field.showLabel !== undefined) {
            showLabel = this.props.field.showLabel;
        }

        const field: React.ReactFragment = Radio.getField(headLineLabel, showLabel, label, layoutLabel, columns, options, nonMandatoryOption);

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            return <div>
                {field}
            </div>
        } else {
            return this.renderField(field, name);
        }
    }

    private getCurrentValue(): string {
        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            if (this.props.value !== undefined) {
                return this.props.value;
            } else {
                return '';
            }
        } else {
            return this.state.value;
        }
    }

    private static getField(headLineLabel: string,
                            showLabel: boolean,
                            labelText: string,
                            layoutLabel: string,
                            columns: { columns: number },
                            options: {} | React.ReactNodeArray,
                            nonMandatoryOption: {} | React.ReactNodeArray): React.ReactFragment {

        const label = <h5>
            <label data-testid={headLineLabel}
                   aria-label={headLineLabel}>
                {labelText}
            </label>
        </h5>;

        const optionsFragment = <div aria-label={layoutLabel}
                                     style={columns}
        >
            {options}
            {nonMandatoryOption}
        </div>;

        if (!showLabel) {
            return <div>
                {optionsFragment}
            </div>;
        }

        return <div>
            {label}
            {optionsFragment}
        </div>;
    }

    componentDidMount() {
        let prefilledValues = this.props.prefilledValues;
        if (prefilledValues !== undefined) {
            this.set(prefilledValues);
        }
    }

    private columns() {
        let columns = {columns: 1};
        const optionsLayout = this.props.field.optionsLayout;
        if (optionsLayout !== undefined) {
            const device = this.props.device;

            if (device === DESKTOP) {
                columns.columns = optionsLayout.desktop.columns;
            }

            if (device === MOBILE) {
                columns.columns = optionsLayout.mobile.columns;
            }
        }
        return columns;
    }

    private getOptions(currentValue: string): React.ReactFragment {
        const fieldName: string = this.props.field.name;
        const options: (string | Field)[] | undefined = this.props.field.options;

        if (options !== undefined) {
            return options.map((option: string | Field) => {
                    if (typeof option === 'string') {
                        return this.makeAnExpandedField(option, fieldName, currentValue);
                    }

                    const name = option.name;
                    if (!!option.options && option.options.length === 0) {
                        return this.makeAnExpandedField(name, fieldName, currentValue);
                    }

                    if (isEqualDisregardingDuplicationMarkers(currentValue, name)) {
                        return this.createExpandedOption(name, fieldName, currentValue, option);
                    }

                    if (this.props.action !== undefined) {
                        // Monkey patching the name as it seems too hard to actually configure the option from group by in statistics
                        // The result is that we can expand in one level, but not more. Filed as a bug.
                        if (this.props.action.type === STATISTICS) {
                            if (!name.startsWith(fieldName)) {
                                const optionName = fieldName + '.' + name;
                                return this.createExpandableOption(optionName, fieldName, currentValue);
                            }
                        }
                    }

                    return this.createExpandableOption(name, fieldName, currentValue);
                }
            );
        } else {
            return <div/>;
        }
    }

    /**
     * Create default case for string option
     */
    private makeAnExpandedField(option: string, fieldName: string, currentValue: string): React.ReactFragment {
        const optionName: string = fieldName + "." + option;
        const onChange = () => this.onChange(optionName);

        let drawPaddingDivs = this.shouldDrawPaddingDivs(optionName);
        if (drawPaddingDivs) {
            return <React.Fragment key={optionName}>
                {this.getOption(fieldName, optionName, currentValue, onChange)}
                {this.addPadding()}
            </React.Fragment>
        } else {
            return this.getOption(fieldName, optionName, currentValue, onChange);
        }
    }

    /**
     * Create option with expanded fields
     */
    private createExpandedOption(optionName: string,
                                 fieldName: string,
                                 currentValue: string,
                                 option: Field): React.ReactFragment {
        const onChange = () => this.onChange(optionName);
        const parent = this.getOption(fieldName, optionName, currentValue, onChange);
        const device = this.props.device;
        let user: any = {
            dateFormat: 'yyyy-MM-dd'
        }
        if (this.props.user !== undefined) {
            user = this.props.user;
        }
        const cssId = uuidv4();

        let children: React.ReactFragment[] = [];

        const shareMyValue = this.props.shareMyValue;
        if (option.fields !== undefined && option.fields.length > 0) {
            const frontendVersion = this.props.frontendVersion;
            if (frontendVersion !== undefined && frontendVersion === V3) {
                if (this.props.onChange !== undefined && this.props.event !== undefined && this.props.action !== undefined) {
                    const onChange = this.props.onChange;
                    const event: EventNG = this.props.event;
                    const action: Action = this.props.action;

                    children = option.fields.map((f: Field) => {
                        let duplicationIndex: string = '0';
                        if (this.props.duplicationIndex !== undefined) {
                            duplicationIndex = this.props.duplicationIndex;
                        }

                        let visibleField = getFieldNG(f, event, onChange, action, user, {cssId: cssId});
                        let key = f.name + '-' + duplicationIndex;
                        return <div key={key}>{visibleField}</div>;
                    });
                }
            } else {
                children = option.fields.map((f: Field) => {
                    return getFields(f, this.fieldReferences, device, "yyyy-mm-dd", shareMyValue);
                });
            }
        } else {
            const frontendVersion = this.props.frontendVersion;
            if (frontendVersion !== undefined && frontendVersion === V3) {

                if (this.props.onChange !== undefined && this.props.event !== undefined && this.props.action !== undefined) {
                    const onChange = this.props.onChange;
                    const event: EventNG = this.props.event;
                    const action: Action = this.props.action;

                    const child = getFieldNG(option, event, onChange, action, user, {cssId: cssId});
                    children.push(child);
                }
            } else {
                const child = getField(option, this.fieldReferences, device, "yyyy-mm-dd", shareMyValue);
                children.push(child);
            }
        }

        return <div key={fieldName}>
            {parent}
            <div id={cssId} onClick={() => this.changeClassAfterDelay(cssId)} className={"expandingSpacerWrapper"}>
                <div className={"pl-4 fade-in"}>
                    {children}
                </div>
            </div>
        </div>
    }

    /**
     * Create option with un-expanded expandable fields.
     * This is the option holding stuff that can be expanded.
     */
    private createExpandableOption(optionName: string, fieldName: string, currentValue: string): React.ReactFragment {
        const onChange = () => this.onChange(optionName);

        const ref: any = React.createRef();
        this.fieldReferences.set(optionName, ref);

        let drawPaddingDivs = this.shouldDrawPaddingDivs(optionName);
        if (drawPaddingDivs) {
            return <React.Fragment key={optionName}>
                {this.getOption(fieldName, optionName, currentValue, onChange)}
                {this.addPadding()}
            </React.Fragment>
        } else {
            return this.getOption(fieldName, optionName, currentValue, onChange);
        }
    }

    private addPadding() {
        const rows: any[] = [];
        for (let i = 0; i < getNumberOfSpacers(this.props.field, this.getCurrentValue()); i++) {
            rows.push({nr: i});
        }

        return <>
            {
                rows.map(elem => {
                    return <div className={"expandingSpacer"} key={elem.nr}>
                    </div>
                })
            }
        </>;
    }

    private getNonMandatoryOption(name: string, currentValue: string, mandatory: boolean): React.ReactFragment {
        if (mandatory) {
            return <div/>
        } else {
            const optionName: string = name + "." + NOT_REGISTERED;
            const onChange = () => this.onChange("");
            return this.getOption(name, optionName, currentValue, onChange);
        }
    }

    private getOption(name: string, optionName: string, currentValue: string, onChange: () => void): React.ReactFragment {
        const id: string = uuidv4();
        let labelText: string;
        let checked: boolean = false;
        let shouldPadLastElem = false;
        if (optionName.endsWith(NOT_REGISTERED)) {
            shouldPadLastElem = this.shouldPadLastElement();
            labelText = TranslationService.translation(NOT_REGISTERED);
            if (currentValue === "") {
                checked = true;
            }
        } else {
            checked = isEqualDisregardingDuplicationMarkers(optionName, currentValue);
            labelText = TranslationService.translation(optionName);
        }

        let radioGroup: string = name;
        if (this.props.duplicationIndex !== undefined) {
            radioGroup = name + '-' + this.props.duplicationIndex;
        }

        return <React.Fragment key={optionName + "lastElem"}>

            <span key={optionName}
                  className={"row m-0 p-0"}
                  onChange={onChange}>

                    <label className={"mb-0"} htmlFor={id}>
                <input type={"radio"}
                       name={radioGroup}
                       id={id}
                       data-testid={id}
                       readOnly={true}
                       checked={checked}
                       aria-label={optionName}
                />
                        {labelText}</label>

            </span>
            {
                shouldPadLastElem ? this.addPadding() : ""
            }
        </React.Fragment>;
    }

    private onChange(option: string): void {
        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            if (this.props.onChange) {
                let field = this.props.field;
                const name: string = field.name;
                let duplicationIndex: string = '0';
                if (this.props.duplicationIndex !== undefined) {
                    duplicationIndex = this.props.duplicationIndex;
                }

                this.props.onChange(name, option, duplicationIndex, true, field);
            }
        } else {
            this.setState({value: option});
        }
    }

    values(): Value[] {
        const name = this.props.field.name;
        const value = this.getCurrentValue();

        if (this.isValid().valid) {
            if (value !== "") {
                if (this.hasExpandableField(value)) {
                    const parent = createSingleValue(name, value);
                    let expanded = this.getExpandedValues(value);
                    return parent.concat(expanded);
                }

                let values: Value[] = createSingleValue(name, value);
                this.fieldReferences.forEach((value: any) => {
                    const fieldComponent: FieldComponent = value.current;
                    if (fieldComponent !== null) {
                        const vals: Value[] = fieldComponent.values();
                        values = values.concat(vals);
                    }
                });

                return values;
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    getExpandedValues(value: string): Value[] {
        const expanded = this.fieldReferences.get(value);
        const current = expanded.current;
        if (current !== null) {
            return current.values();
        }

        return [];
    }

    hasExpandableField(value: string): boolean {
        const options: (string | Field)[] | undefined = this.props.field.options;
        if (options !== undefined) {
            const option = options.find((option: string | Field) => {
                if (typeof option !== 'string') {
                    return option.name === value
                }

                return undefined;
            });

            return Radio.selectedValueHasExpandableFields(option);
        }
        return false;
    }

    private static selectedValueHasExpandableFields(wantedOption: string | Field | undefined): boolean {
        if (wantedOption !== undefined) {
            if (typeof wantedOption !== 'string') {
                if ((wantedOption as Field).type === 'text') {
                    return true;
                }
            }
        }

        return (wantedOption !== undefined &&
            typeof wantedOption !== 'string' &&
            wantedOption.options !== undefined &&
            wantedOption.options.length > 0);
    }

    isValid(): FieldError {
        const name = this.props.field.name;
        const value = this.getCurrentValue();
        const mandatory = this.props.field.mandatory;

        return isSingleValueValid(name, value, mandatory);
    }

    clear(): void {
        let visible = this.isConditionalfield();
        this.setState({
            value: "",
            visible: visible
        });
    }

    set(values: Value[]): void {
        if (values) {
            let field = this.props.field;
            values.forEach((value: Value) => {
                const name = field.name;
                if (value.fieldName === name) {
                    const newValue: string = value.values[0];
                    this.setState({
                        value: newValue
                    }, () => {
                        this.fieldReferences.forEach((reference: any) => {
                            if (reference !== null) {
                                const expanded: FieldComponent = reference.current;
                                if (expanded) {
                                    expanded.set(values);
                                }
                            }
                        });
                    });
                }
            });
        }
    }

    private shouldDrawPaddingDivs(optionName: string) {
        return !!(this.getCurrentValue() &&
            this.isOptionBreakpoint(optionName) &&
            this.optionIsNotInSameColumnAsValue(optionName) &&
            !this.isOptionLastBreakPoint(optionName));
    }

    private isOptionBreakpoint(optionName: string): boolean {
        const breakPoints = getBreakpoints(this.props.field, Register.getDevice())

        let found = false;
        breakPoints.forEach(elem => {
            if (typeof elem === 'string') {
                if (optionName === elem) {
                    found = true;
                }
            } else {
                if (elem.name === optionName) {
                    found = true;
                }
            }
        });

        return found;
    }

    private optionIsNotInSameColumnAsValue(optionName: string) {
        const valueColumn = findValue(this.getCurrentValue(), this.props.field);
        const optionColumn = findValue(optionName, this.props.field);

        return (valueColumn !== optionColumn);
    }

    private isOptionLastBreakPoint(optionName: string): boolean {
        const layout = Register.getDevice();
        const breakPoints = getBreakpoints(this.props.field, layout)

        const lastElem = breakPoints[breakPoints.length - 1];

        if (typeof lastElem === 'string') {
            return lastElem === optionName;
        } else {
            return lastElem.name === optionName;
        }
    }

    private shouldPadLastElement(): boolean {
        let columnCount;
        if (Register.getDevice() === MOBILE) {
            columnCount = this.props.field.optionsLayout ? this.props.field.optionsLayout.mobile.columns : 1;
        } else {
            columnCount = this.props.field.optionsLayout ? this.props.field.optionsLayout.desktop.columns : 1;
        }

        const chosenValueColumn = findValue(this.getCurrentValue(), this.props.field);

        return !((chosenValueColumn + 1) >= columnCount);
    }

    private changeClassAfterDelay(cssId: string) {
        setTimeout(function () {
            const wanted = document.getElementById(cssId)
            wanted?.classList.add("allowOverflow")
        }, 350);
    }
}

export default Radio;
