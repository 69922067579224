import {StatisticsService} from "./StatsticsService";
import {AgeFilterSelection, GraphOptions} from "../../statistics/v2/GraphOptions";
import Value from "./Value";
import {getSecurityToken} from "../../../infra/SecurityToken";
import {logout} from "../../../infra/Logout";
import {Graph} from "../../graph/Graph";

export interface StatisticsRequest {
    action: string,
    version: number,
    scope: string,
    groupBy: string,
    currentOrganisationId?: string,
    graphType?: string,
    movingAverage?: number,
    periodSelection?: {
        showPeriodSelection: boolean,
        periodFrom: string,
        periodTo: string
    },
    lastSelection?: {
        showAmount: boolean,
        amount: number
    },
    firstSelection?: {
        showAmount: boolean,
        amount: number
    },
    filter: {
        fields: { field: string }[],
        ageSelections: AgeFilterSelection[],
        users: any[]
    }
}

export class StatisticsBackendService implements StatisticsService {
    getGraph(actionName: string, actionVersion: number, graphOptions: GraphOptions, scope: string): Promise<Graph> {
        const url: string = this.getUrl("/api/v1/stats/" + scope)
        const request: StatisticsRequest = this.getRequest(actionName, actionVersion, graphOptions, scope);

        return fetch(url, {
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include',
            method: "POST",
            body: JSON.stringify(request)
        })
            .then(res => {
                if (res.status === 401) {
                    logout();
                }

                return res.json()
            })
            .then()
            .catch((error) => {
                console.log(error);
            });
    }

    getRequest(actionName: string, actionVersion: number, graphOptions: GraphOptions, scope: string): StatisticsRequest {
        let currentOrganisationId: string | undefined = undefined;
        if (graphOptions.currentOrganisation !== undefined) {
            currentOrganisationId = graphOptions.currentOrganisation.organisationId;
        }

        const request: StatisticsRequest =
            {
                action: actionName,
                version: actionVersion,
                scope: scope,
                groupBy: graphOptions.groupBy,
                currentOrganisationId: currentOrganisationId,
                filter: {
                    fields: [],
                    ageSelections: [],
                    users: []
                },
            };

        if (graphOptions.graphType !== undefined) {
            request.graphType = graphOptions.graphType;
        }

        if (graphOptions.movingAverage !== undefined) {
            request.movingAverage = graphOptions.movingAverage;
        }

        if (graphOptions.periodSelection !== undefined) {
            request.periodSelection = {
                showPeriodSelection: true,
                periodFrom: graphOptions.periodSelection.periodFrom,
                periodTo: graphOptions.periodSelection.periodTo
            };
        }

        if (graphOptions.firstSelection !== undefined) {
            request.firstSelection = {
                showAmount: true,
                amount: graphOptions.firstSelection.amount
            };
        }

        if (graphOptions.lastSelection !== undefined) {
            request.lastSelection = {
                showAmount: true,
                amount: graphOptions.lastSelection.amount
            }
        }

        if (graphOptions.values !== undefined) {
            graphOptions.values.forEach((value: Value) => {
                value.values.forEach((f: string) => {
                    const field: { field: string } = {field: f};
                    request.filter.fields.push(field);
                })
            });
        }

        if (graphOptions.ageSelection !== undefined) {
            request.filter.ageSelections = [];
            graphOptions.ageSelection.forEach((ageSelection: AgeFilterSelection) => {
                request.filter.ageSelections.push(ageSelection);
            });
        }

        return request;
    }

    private getUrl(path: string): string {
        let host: string;
        if (process.env.REACT_APP_LOCALHOST === undefined) {
            host = '';
        } else {
            host = process.env.REACT_APP_LOCALHOST;
        }

        return host + path;
    }
}
